import React, { useState, useEffect } from 'react';

const Tool4 = ({ theme }) => {
  const [ships, setShips] = useState([]);
  const [selectedShip, setSelectedShip] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showOnlyPMH, setShowOnlyPMH] = useState(true);
  // Lagrer forslag per felt (key)
  const [suggestionValues, setSuggestionValues] = useState({});

  // Hjelpefunksjon for regex med støtte for alternative skrivemåter
  const createSearchRegex = (word) => {
    const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let regexStr = '';
    for (const char of word) {
      const lowerChar = char.toLowerCase();
      if (lowerChar === 'ø' || lowerChar === 'o') {
        regexStr += '(?:o|ø|oe)';
      } else if (lowerChar === 'å' || lowerChar === 'a') {
        regexStr += '(?:a|å|aa)';
      } else if (lowerChar === 'æ') {
        regexStr += '(?:æ|a|e|ae)';
      } else {
        regexStr += escapeRegex(char);
      }
    }
    return new RegExp(regexStr, 'i');
  };

  // Hent listen over skip (kun én gang)
  const fetchShips = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const response = await fetch(
        'https://pmh-intern.no:8500/api/v1/ship/edit-ship/list',
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 403) {
        console.log('Logg ut!');
        window.location.href = '/login';
        return;
      }
      const data = await response.json();
      const shipArray = Array.isArray(data) ? data : Object.values(data);
      setShips(shipArray);
    } catch (error) {
      console.error('Feil ved henting av skipinfo:', error);
    }
  };

  useEffect(() => {
    fetchShips();
  }, []);

  const styles = getStyles(theme);

  // Filtrer skip basert på søk og toggle
  const filteredShips = ships.filter((ship) => {
    if (showOnlyPMH && ship.pmh !== 1) return false;
    if (searchQuery) {
      const regex = createSearchRegex(searchQuery);
      return regex.test(ship.name || '');
    }
    return true;
  });

  // Når et skip klikkes, hentes detaljene
  const handleShipClick = async (ship) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const response = await fetch(
        `https://pmh-intern.no:8500/api/v1/ship/edit-ship/?mmsi=${ship.mmsi}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 403) {
        console.log('Logg ut!');
        window.location.href = '/login';
        return;
      }
      const data = await response.json();
      setSelectedShip(data);
      // Ved nytt valg tømmes forslagene
      setSuggestionValues({});
    } catch (error) {
      console.error('Feil ved henting av skipdetaljer:', error);
    }
  };

  // Oppdater forslag for et felt lokalt
  const handleSuggestionChange = (key, value) => {
    setSuggestionValues((prev) => ({ ...prev, [key]: value }));
  };

  // Hent oppdatert detaljinfo for valgt skip uten å tømme alle forslag
  const refreshSelectedShip = async (mmsi) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const response = await fetch(
        `https://pmh-intern.no:8500/api/v1/ship/edit-ship/?mmsi=${mmsi}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 403) {
        console.log('Logg ut!');
        window.location.href = '/login';
        return;
      }
      const data = await response.json();
      setSelectedShip(data);
    } catch (error) {
      console.error('Feil ved oppfrisking av skipdetaljer:', error);
    }
  };

  // Funksjon for å sende oppdatering for et bestemt felt
  const updateField = async (key) => {
    // Ikke oppdater felt som ikke kan endres
    if (key === 'id' || key === 'mmsi') return;

    const newValue = suggestionValues[key];
    if (newValue === undefined || newValue === '') return;

    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const response = await fetch(
        'https://pmh-intern.no:8500/api/v1/ship/edit-ship/update',
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            mmsi: selectedShip.mmsi,
            field: key,
            value: newValue,
          }),
        }
      );
      if (response.status === 403) {
        console.log('Logg ut!');
        window.location.href = '/login';
        return;
      }
      const result = await response.json();
      console.log('Oppdatering vellykket', result);
      // Etter oppdatering oppdateres skipdetaljene, og kun input for denne nøkkelen tømmes
      await refreshSelectedShip(selectedShip.mmsi);
      setSuggestionValues((prev) => ({ ...prev, [key]: '' }));
    } catch (error) {
      console.error('Feil under oppdatering:', error);
    }
  };

  // Render rader for alle felter som regnes som PMH-info (uten "man_" og "sdir_")
  const renderDetailsRows = () => {
    if (!selectedShip) return null;
    const pmhKeys = Object.keys(selectedShip).filter(
      (key) => !key.startsWith('man_') && !key.startsWith('sdir_')
    );
    return pmhKeys.map((key) => {
      const pmhValue = selectedShip[key] ?? '';
      const sdirKey = 'sdir_' + key;
      const sdirValue = selectedShip[sdirKey] ?? '';
      const disableUpdate = key === 'id' || key === 'mmsi';
      return (
        <React.Fragment key={key}>
          <div style={styles.gridLabel}>{key}</div>
          <div style={styles.gridCell}>
            <input
              style={styles.input}
              value={suggestionValues[key] || ''}
              onChange={(e) => handleSuggestionChange(key, e.target.value)}
              placeholder="Skriv forslag..."
            />
          </div>
          <div style={styles.gridCell}>
            <button
              style={{
                ...styles.updateButton,
                backgroundColor: disableUpdate
                  ? 'red'
                  : styles.updateButton.backgroundColor,
                cursor: disableUpdate ? 'not-allowed' : 'pointer',
              }}
              onClick={() => updateField(key)}
              disabled={disableUpdate}
              title={disableUpdate ? 'Kan ikke endres' : 'Send oppdatering'}
            >
              {disableUpdate ? '✖' : '✔'}
            </button>
          </div>
          <div style={styles.gridCell}>{pmhValue}</div>
          <div style={styles.gridCell}>{sdirValue}</div>
        </React.Fragment>
      );
    });
  };

  return (
    <div style={styles.container}>
      {/* Venstre kolonne */}
      <div style={styles.leftColumn}>
        <div style={styles.fixedControls}>
          <h2>Liste over skip</h2>
          <div style={styles.controls}>
            <input
              type="text"
              placeholder="Søk etter skip..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={styles.searchInput}
            />
            <label style={styles.toggleLabel}>
              <input
                type="checkbox"
                checked={showOnlyPMH}
                onChange={(e) => setShowOnlyPMH(e.target.checked)}
              />
              Kun PMH-skip
            </label>
          </div>
        </div>
        <div style={styles.shipList}>
          {filteredShips.map((ship) => {
            const shipStyle = {
              ...styles.shipCard,
              backgroundColor:
                ship.pmh === 1
                  ? theme === 'light'
                    ? '#d1e7dd'
                    : '#285e61'
                  : styles.shipCard.backgroundColor,
            };
            return (
              <div
                key={ship.mmsi || ship.name}
                style={shipStyle}
                onClick={() => handleShipClick(ship)}
              >
                <p>
                  <strong>{ship.name || 'Ukjent navn'}</strong>
                </p>
                <p>MMSI: {ship.mmsi || 'Ukjent'}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Høyre kolonne */}
      <div style={styles.rightColumn}>
        <h2>Skipdetaljer</h2>
        {selectedShip ? (
          <div style={styles.detailsGrid}>
            <div style={styles.gridHeader}>Felt</div>
            <div style={styles.gridHeader}>Ditt forslag</div>
            <div style={styles.gridHeader}>Oppdater</div>
            <div style={styles.gridHeader}>PMH-info</div>
            <div style={styles.gridHeader}>SDIR-info</div>
            {renderDetailsRows()}
          </div>
        ) : (
          <p>Ingen skip valgt</p>
        )}
      </div>
    </div>
  );
};

export default Tool4;

const getStyles = (theme) => {
  const isLight = theme === 'light';
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vh - 111px)',
      backgroundColor: isLight ? '#f5f5f5' : '#2e2e2e',
      color: isLight ? '#000' : '#fff',
      fontFamily: 'sans-serif',
    },
    leftColumn: {
      width: '25%',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box',
      backgroundColor: isLight ? '#ffffff' : '#3a3a3a',
    },
    fixedControls: {
      flexShrink: 0,
    },
    shipList: {
      flex: 1,
      overflowY: 'auto',
      marginTop: '10px',
    },
    rightColumn: {
      width: '75%',
      padding: '20px',
      boxSizing: 'border-box',
      overflowY: 'auto',
    },
    controls: {
      marginBottom: '15px',
    },
    searchInput: {
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      boxSizing: 'border-box',
    },
    toggleLabel: {
      display: 'block',
      fontSize: '14px',
      cursor: 'pointer',
      userSelect: 'none',
    },
    shipCard: {
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      borderRadius: '5px',
      padding: '10px',
      marginBottom: '10px',
      backgroundColor: isLight ? '#fafafa' : '#444',
      cursor: 'pointer',
    },
    detailsGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr auto 1fr 1fr',
      gap: '10px',
      alignItems: 'stretch',
    },
    gridHeader: {
      fontWeight: 'bold',
      padding: '8px',
      borderBottom: isLight ? '2px solid #ccc' : '2px solid #555',
      textAlign: 'center',
    },
    gridLabel: {
      padding: '8px',
      fontWeight: 'bold',
      borderBottom: isLight ? '1px solid #eee' : '1px solid #444',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridCell: {
      padding: '8px',
      borderBottom: isLight ? '1px solid #eee' : '1px solid #444',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      width: '100%',
      padding: '6px',
      borderRadius: '4px',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      boxSizing: 'border-box',
    },
    updateButton: {
      padding: '6px 8px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: isLight ? '#007bff' : '#375a7f',
      color: '#fff',
      cursor: 'pointer',
    },
  };
};
