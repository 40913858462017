import React, { useState, useEffect } from 'react';

const Tool4 = ({ styles }) => {
  const [serverInfo, setServerInfo] = useState(null);
  const [processes, setProcesses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' eller 'desc'

  // Funksjon for å hente data fra API-et
  const fetchStatus = async () => {
    try {

      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }

      const response = await fetch(
        `https://pmh-intern.no:8500/api/status`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 403) {
        console.log('logg ut!');
        window.location.href = '/login';
      }

      const data = await response.json();

      //const response = await axios.get('https://pmh-intern.no:8500/api/status');
      if(data.server) setServerInfo(data.server);
      if(data.processes) setProcesses(data.processes);
    } catch (error) {
      console.error('Error fetching server status:', error);
    }
  };

  // Poll API-et med et intervall for live oppdateringer (f.eks. hvert 1000 ms)
  useEffect(() => {
    // Hent status med en gang
    fetchStatus();
    const intervalId = setInterval(() => {
      fetchStatus();
    }, 1000); // oppdater hvert 1. sekund

    return () => clearInterval(intervalId);
  }, []);

  // Formatere uptime for serverinfo (tar imot uptime i sekunder eller med "s" på slutten)
  const formatUptimeServer = (uptimeInput) => {
    // Hvis uptimeInput er en streng med "s", fjern den
    let uptime = typeof uptimeInput === 'string'
      ? parseInt(uptimeInput.replace('s', ''), 10)
      : parseInt(uptimeInput, 10);

    const days = Math.floor(uptime / (3600 * 24));
    const hours = Math.floor((uptime % (3600 * 24)) / 3600);
    const minutes = Math.floor((uptime % 3600) / 60);
    const seconds = uptime % 60;

    if (days > 0) return `${days}d ${hours}h ${minutes}m`;
    if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;
    if (minutes > 0) return `${minutes}m ${seconds}s`;
    return `${seconds}s`;
  };

  // Formatere uptime for PM2-prosesser. Her er uptime en timestamp for når prosessen ble startet.
  const formatUptimePM2 = (startTimestampMs) => {
    const now = Date.now(); // nåværende tid i ms
    let uptime = Math.floor((now - startTimestampMs) / 1000); // konverter til sekunder

    const days = Math.floor(uptime / (3600 * 24));
    const hours = Math.floor((uptime % (3600 * 24)) / 3600);
    const minutes = Math.floor((uptime % 3600) / 60);
    const seconds = uptime % 60;

    if (days > 0) return `${days}d ${hours}h ${minutes}m`;
    if (hours > 0) return `${hours}h ${minutes}m ${seconds}s`;
    if (minutes > 0) return `${minutes}m ${seconds}s`;
    return `${seconds}s`;
  };

  // Håndter sortering ved klikk på header
  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  // Filtrer prosesser basert på søkestreng
  let filteredProcesses = processes.filter((process) =>
    process.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sorter de filtrerte prosessene
  filteredProcesses.sort((a, b) => {
    let aVal = a[sortColumn];
    let bVal = b[sortColumn];

    if (typeof aVal === 'string') aVal = aVal.toLowerCase();
    if (typeof bVal === 'string') bVal = bVal.toLowerCase();

    return sortOrder === 'asc' ? (aVal > bVal ? 1 : -1) : (aVal < bVal ? 1 : -1);
  });

  // Definer tabell-headere
  const headers = [
    { key: 'name', label: 'Prosessnavn' },
    { key: 'status', label: 'Status' },
    { key: 'cpu', label: 'CPU (%)' },
    { key: 'memory', label: 'Minnebruk' },
    { key: 'uptime', label: 'Uptime' },
    { key: 'restarts', label: 'Restarts' },
  ];

  return (
    <div style={styles.contentContainer}>
      <h2>Serverstatus</h2>

      {/* Server Info */}
      {serverInfo ? (
        <div style={styles.serverInfo}>
          <p><strong>Uptime:</strong> {formatUptimeServer(serverInfo.uptime)}</p>
          <p style={{ marginBottom: '5px' }}><strong>Load Average:</strong></p>
          <ul>
            {serverInfo.loadavg.map((avg, index) => {
              const labels = ["01 min", " 05 min", "15 min"];
              const percentage = Math.round((avg / serverInfo.cpuCount) * 100);

              return (
                <li key={index}>
                  {labels[index]}: {avg.toFixed(2)} / {serverInfo.cpuCount} kjerner ({percentage}% belastning)
                </li>
              );
            })}
          </ul>
          <p><strong>CPU Modell:</strong> {serverInfo.cpuModel} ({serverInfo.cpuCount} kjerner)</p>
          <p><strong>CPU Bruk:</strong> {serverInfo.cpuUsage}%</p>
          <p><strong>RAM:</strong> {serverInfo.freeMemory} ledig av {serverInfo.totalMemory}</p>
          <p><strong>OS:</strong> {serverInfo.platform} ({serverInfo.arch})</p>
        </div>
      ) : (
        <p>Laster serverinformasjon...</p>
      )}

      {/* Prosess-tabell */}
      {filteredProcesses.length > 0 ? (
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key} style={styles.th} onClick={() => handleSort(header.key)}>
                    {header.label}
                    {sortColumn === header.key && (sortOrder === 'asc' ? ' ▲' : ' ▼')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredProcesses.map((process, index) => (
                <tr key={process.name} style={index % 2 === 0 ? styles.trEven : styles.trOdd}>
                  <td style={styles.td}>{process.name}</td>
                  <td style={styles.td}>{process.status === 'online' ? '🟢 Online' : '🔴 Offline'}</td>
                  <td style={styles.td}>{process.cpu}%</td>
                  <td style={styles.td}>{process.memory}</td>
                  <td style={styles.td}>{formatUptimePM2(process.uptime)}</td>
                  <td style={styles.td}>{process.restarts}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Ingen prosesser funnet.</p>
      )}
    </div>
  );
};

export default Tool4;
