import React, { useState, useEffect } from 'react';

const Service2 = () => {
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState('grid');
  const [selectedImage, setSelectedImage] = useState(null);

  // Hent liste over bildefilnavn fra API
  useEffect(() => {
    const fetchImageList = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('Ingen token, redirect til login');
        window.location.href = '/login';
        return;
      }
      try {
        const response = await fetch('https://pmh-intern.no:8500/api/v1/service/support-images', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setImages(data.images);
      } catch (error) {
        console.error('Feil ved henting av bildefiler:', error);
      }
    };
    fetchImageList();
  }, []);

  // Hent bildefilene og konverter til blob-URL
  useEffect(() => {
    const fetchImages = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('Ingen token, redirect til login');
        window.location.href = '/login';
        return;
      }
      try {
        const urls = await Promise.all(
          images.map(async (filename) => {
            const response = await fetch(`https://pmh-intern.no:8500/api/v1/service/support-images/${filename}`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            if (!response.ok) {
              console.error(`Feil ved henting av ${filename}`);
              return null;
            }
            const blob = await response.blob();
            return URL.createObjectURL(blob);
          })
        );
        setImageUrls(urls.filter(url => url !== null));
        setLoading(false);
      } catch (error) {
        console.error('Feil ved lasting av bilder:', error);
        setLoading(false);
      }
    };
    if (images.length > 0) {
      fetchImages();
    }
  }, [images]);

  // Filtrering og sortering
  const filteredAndSortedImages = imageUrls
    .filter((img) => img.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a)));

  const closeModal = () => setSelectedImage(null);

  return (
    <>
      {/* Innebygd CSS */}
      <style>{`
        .content-container {
          padding: 20px;
          text-align: center;
          font-family: Arial, sans-serif;
        }
        .search-container {
          margin-bottom: 20px;
        }
        .search-input {
          padding: 10px;
          width: 300px;
          border-radius: 5px;
          border: 1px solid #ccc;
          font-size: 16px;
        }
        .controls-container {
          margin-bottom: 20px;
          display: flex;
          justify-content: center;
          gap: 10px;
        }
        .control-button {
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          background-color: #007BFF;
          color: #fff;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
        }
        .control-button:hover {
          background-color: #0056b3;
        }
        .gallery-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
          gap: 15px;
          padding: 10px;
        }
        .gallery-list {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 10px;
        }
        .image-wrapper-grid {
          position: relative;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0,0,0,0.1);
          transition: transform 0.3s ease;
          cursor: pointer;
        }
        .image-wrapper-grid:hover {
          transform: scale(1.05);
        }
        .image-wrapper-list {
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 8px rgba(0,0,0,0.1);
          transition: background 0.3s ease;
          cursor: pointer;
        }
        .image-wrapper-list:hover {
          background: #f0f0f0;
        }
        .gallery-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          position: relative;
          max-width: 90%;
          max-height: 90%;
          border-radius: 8px;
        }
        .modal-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }
        .modal-close-button {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: #fff;
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          line-height: 30px;
          text-align: center;
        }
      `}</style>

      <div className="content-container">
        <h2>Bildesamling</h2>
        
        {/* Søkefelt */}
        <div className="search-container">
          <input
            type="text"
            placeholder="Søk etter bilder..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>
        
        {/* Kontrollpanel for sortering og visningsmodus */}
        <div className="controls-container">
          <button 
            onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            className="control-button"
          >
            Sorter {sortOrder === 'asc' ? '▲' : '▼'}
          </button>
          <button
            onClick={() => setViewType(viewType === 'grid' ? 'list' : 'grid')}
            className="control-button"
          >
            {viewType === 'grid' ? 'Listevisning' : 'Rutenettvisning'}
          </button>
        </div>
        
        {/* Lastestatus */}
        {loading && <p>Laster bilder...</p>}
        
        {/* Bildegalleri */}
        {filteredAndSortedImages.length > 0 ? (
          <div className={viewType === 'grid' ? 'gallery-grid' : 'gallery-list'}>
            {filteredAndSortedImages.map((image, index) => (
              <div
                key={index}
                className={viewType === 'grid' ? 'image-wrapper-grid' : 'image-wrapper-list'}
                onClick={() => viewType === 'grid' && setSelectedImage(image)}
              >
                <img src={image} alt={`Bilde ${index + 1}`} className="gallery-image" />
              </div>
            ))}
          </div>
        ) : (
          !loading && <p>Ingen bilder funnet.</p>
        )}
        
        {/* Modal for stort bilde */}
        {selectedImage && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-close-button" onClick={closeModal}>
                &times;
              </button>
              <img src={selectedImage} alt="Stort bilde" className="modal-image" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Service2;
