import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
const DEFAULT_IMAGE_URL = 'https://pmh-intern.no/default.webp';

// Cache for ikonene
const iconCache = {};

// Funksjon for å lage et canvas-basert ikon
function createCanvasIcon(color) {
  if (iconCache[color]) return iconCache[color];

  const width = 16;
  const height = 20;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = color;
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 0.8;
  ctx.beginPath();
  ctx.moveTo(width / 2, 0);
  ctx.lineTo(width, height);
  ctx.lineTo(width / 2, height * 0.8);
  ctx.lineTo(0, height);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  const icon = L.icon({
    iconUrl: canvas.toDataURL(),
    iconSize: [width, height],
    iconAnchor: [width / 2, height / 2],
  });
  iconCache[color] = icon;
  return icon;
}

// Funksjon for å hente stiler basert på tema
const getStyles = (theme) => {
  const isLight = theme === 'light';
  return {
    container: {
      backgroundColor: isLight ? '#f5f5f5' : '#2e2e2e',
      color: isLight ? '#000' : '#fff',
      padding: '20px',
      fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
      minHeight: '100vh',
    },
    button: {
      backgroundColor: '#007BFF',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '8px 16px',
      cursor: 'pointer',
      fontSize: '14px',
    },
    // Tilbake-knappen – nå inni headeren
    backButton: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '14px',
      cursor: 'pointer',
      padding: '4px 8px',
      color: isLight ? '#007BFF' : '#66B2FF',
      marginBottom: '10px',
    },
    // Header med båtinfo – posisjoneres med relativ z-index
    headerContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: isLight ? '#ffffff' : '#444444',
      border: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '20px',
      position: 'relative',
      zIndex: 2,
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: '1 1 300px',
      marginRight: '20px',
    },
    headerImageContainer: {
      flexShrink: 0,
      marginRight: '20px',
      height: '200px', // Nå like høyt som kartet
      cursor: 'pointer',
      borderRadius: '8px',
      overflow: 'hidden',
    },
    headerImage: {
      height: '100%',
      width: 'auto',
      objectFit: 'cover',
    },
    headerText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    headerTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0 0 10px 0',
    },
    headerSubtitle: {
      fontSize: '16px',
      color: isLight ? '#777' : '#aaa',
      margin: 0,
    },
    // Seksjon for flere systemer
    systemsContainer: {
      marginTop: '10px',
    },
    systemItem: {
      fontSize: '14px',
      color: isLight ? '#555' : '#ccc',
    },
    headerMap: {
      flex: '0 0 300px',
      height: '200px',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      position: 'relative',
      zIndex: 1, // Lavere z-index enn headeren
    },
    // Hovedinnhold med to kolonner
    twoColumnLayout: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
    },
    panel: {
      backgroundColor: isLight ? '#ffffff' : '#444444',
      border: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      borderRadius: '8px',
      padding: '20px',
      flex: '1 1 300px',
      marginBottom: '20px',
    },
    sectionTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '15px',
      borderBottom: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      paddingBottom: '8px',
    },
    // Grid for info-poster
    infoGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
      gap: '20px',
      marginBottom: '20px',
    },
    // Hvert info-element vises vertikalt (tittel over verdi)
    infoItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    infoLabel: {
      fontSize: '14px',
      color: isLight ? '#777' : '#aaa',
      marginBottom: '4px',
    },
    infoValue: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: isLight ? '#000' : '#fff',
    },
    loading: {
      textAlign: 'center',
      fontSize: '18px',
      marginTop: '20px',
    },
    // Modal-stiler
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: isLight ? '#fff' : '#444444',
      padding: '20px',
      borderRadius: '8px',
      width: '90%',
      maxWidth: '600px',
      maxHeight: '80vh',
      overflowY: 'auto',
      color: isLight ? '#000' : '#fff',
    },
    formField: {
      marginBottom: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    input: {
      padding: '8px',
      border: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      borderRadius: '4px',
      fontSize: '14px',
    },
    textarea: {
      padding: '8px',
      border: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      borderRadius: '4px',
      fontSize: '14px',
      minHeight: '80px',
    },
    // SDIR-dokumenter
    documentCard: {
      backgroundColor: isLight ? '#fdfdfd' : '#555555',
      border: `1px solid ${isLight ? '#e0e0e0' : '#666666'}`,
      borderRadius: '8px',
      padding: '10px',
      marginBottom: '10px',
    },
    documentTitle: {
      fontSize: '14px',
      fontWeight: 'bold',
      marginBottom: '5px',
      borderBottom: `1px solid ${isLight ? '#cccccc' : '#555555'}`,
      paddingBottom: '3px',
    },
    documentDetail: {
      marginBottom: '3px',
      fontSize: '13px',
      lineHeight: '1.3',
    },
  };
};

const ShipDetailScreen = () => {
  const { mmsi } = useParams();
  const { theme } = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();

  const [shipData, setShipData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingAdditional, setLoadingAdditional] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [loadingProposal, setLoadingProposal] = useState(false);
  const [prevNames, setPrevNames] = useState([]);
  const [username, setUsername] = useState('');

  // Henter SDIR-tilleggsdata
  const fetchAdditionalData = async (sdirId) => {
    setLoadingAdditional(true);
    try {
      const response = await fetch(
        `https://sdir-p-apim-common.azure-api.net/los-vesselsearch-internal/v1/historical-vessel-data/${sdirId}?language=no`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAdditionalData(data);
    } catch (err) {
      console.error('Feil ved henting av tilleggsdata:', err);
    } finally {
      setLoadingAdditional(false);
    }
  };

  // Henter brukerinfo
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) return;
    fetch('https://pmh-intern.no:8502/api/userinfo', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 403) {
          window.location.href = "/login";
        }
        return res.json();
      })
      .then((data) => {
        setUsername(data.username);
      })
      .catch((err) => console.error(err));
  }, []);

  // Henter skipdata
  useEffect(() => {
    const fetchShip = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) return;
        const response = await fetch(`https://pmh-intern.no:8503/api/ship-info?mmsi=${mmsi}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setShipData(data);
        if (data && data.sdir_id) {
          fetchAdditionalData(data.sdir_id);
        }
      } catch (err) {
        console.error('Feil ved henting av skip-data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchShip();
  }, [mmsi]);

  // Henter data for endringsforslag når modal åpnes
  const fetchProposalData = async () => {
    setLoadingProposal(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      const response = await fetch(`https://pmh-intern.no:8503/api/ship-change-proposal?mmsi=${mmsi}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setProposalData(data);
      } else {
        setProposalData(null);
      }
    } catch (err) {
      console.error('Feil ved henting av endringsforslagsdata:', err);
    } finally {
      setLoadingProposal(false);
    }
  };

  const handleOpenProposalModal = () => {
    setShowModal(true);
    fetchProposalData();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setProposalData(null);
    setPrevNames([]);
  };

  // Når modal åpnes, initialiser prevNames
  useEffect(() => {
    if (showModal) {
      let initialPrevNames = [];
      if (proposalData && proposalData.prev_names) {
        try {
          initialPrevNames = Array.isArray(proposalData.prev_names)
            ? proposalData.prev_names
            : JSON.parse(proposalData.prev_names);
        } catch (e) {
          initialPrevNames = [proposalData.prev_names];
        }
      } else if (shipData && shipData.prev_names) {
        try {
          initialPrevNames = Array.isArray(shipData.prev_names)
            ? shipData.prev_names
            : JSON.parse(shipData.prev_names);
        } catch (e) {
          initialPrevNames = [shipData.prev_names];
        }
      } else {
        initialPrevNames = [''];
      }
      setPrevNames(initialPrevNames);
    }
  }, [showModal, proposalData, shipData]);

  const handleSubmitProposal = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const dataToSend = Object.fromEntries(formData.entries());
    dataToSend.prev_names = prevNames;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pmh-intern.no:8503/api/ship-change-proposal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      alert('Forslaget ble sendt inn!');
      setShowModal(false);
    } catch (err) {
      console.error('Feil ved innsending av endringsforslag:', err);
      alert('Noe gikk galt. Vennligst prøv igjen senere.');
    }
  };

  if (loading) {
    return <div style={styles.loading}>Henter skipdata...</div>;
  }

  if (!shipData) {
    return <div style={styles.loading}>Fant ikke skip med MMSI: {mmsi}</div>;
  }

  // Bestem systemer: Dersom shipData.systems finnes, brukes disse; ellers et standardarray med to eksempler.
  const systems =
    shipData.systems ||
    [
      { po: "Default PO 1", customer: "Default Kunde 1", system: "Default System 1" },
      { po: "Default PO 2", customer: "Default Kunde 2", system: "Default System 2" },
    ];

  // Funksjon for å rendere SDIR-dokumenter
  const renderDocuments = () => {
    if (loadingAdditional) {
      return <div style={styles.loading}>Henter SDIR-data...</div>;
    }
    if (additionalData && additionalData.documents && additionalData.documents.length > 0) {
      return additionalData.documents.map((doc, index) => (
        <div key={index} style={styles.documentCard}>
          <div style={styles.documentTitle}>{doc.documentType || 'Dokument'}</div>
          <div style={styles.documentDetail}>
            <strong>Journalnr:</strong> {doc.journalKey || 'Ukjent'}
          </div>
          <div style={styles.documentDetail}>
            <strong>Dokumentdato:</strong> {doc.date || 'Ukjent'}
          </div>
          <div style={styles.documentDetail}>
            <strong>Register:</strong> {doc.register || 'Ukjent'}
          </div>
          <div style={styles.documentDetail}>
            <strong>Journalført:</strong> {doc.journalDate || 'Ukjent'}
          </div>
          {doc.bareboatStatus && (
            <div style={styles.documentDetail}>
              <strong>Bareboat-status:</strong> {doc.bareboatStatus}
            </div>
          )}
          {doc.status && (
            <div style={styles.documentDetail}>
              <strong>Status:</strong> {doc.status}
            </div>
          )}
          {doc.homePort && (
            <div style={styles.documentDetail}>
              <strong>Hjemmehavn:</strong> {doc.homePort}
            </div>
          )}
          {doc.previousRegister && (
            <div style={styles.documentDetail}>
              <strong>Tidligere register:</strong> {doc.previousRegister}
            </div>
          )}
          {doc.voluntaryRegistration !== undefined && (
            <div style={styles.documentDetail}>
              <strong>Frivillig registrering:</strong> {doc.voluntaryRegistration ? 'Ja' : 'Nei'}
            </div>
          )}
          {doc.certificateOfNationalityDate && (
            <div style={styles.documentDetail}>
              <strong>Nasjonalitetsbevis dato:</strong> {doc.certificateOfNationalityDate}
            </div>
          )}
          {doc.memo && (
            <div style={styles.documentDetail}>
              <strong>Merknad:</strong> {doc.memo}
            </div>
          )}
          {doc.srMeasurementData && Object.keys(doc.srMeasurementData).length > 0 && (
            <div style={{ marginTop: '5px', fontSize: '12px' }}>
              <strong>Måledata:</strong>
              {Object.entries(doc.srMeasurementData).map(([key, value]) => (
                <div key={key} style={styles.documentDetail}>
                  <strong>{key}:</strong>{' '}
                  {typeof value === 'object' && value !== null
                    ? `${value.value || 'Ukjent'} ${value.unit || ''}`
                    : value || 'Ukjent'}
                </div>
              ))}
            </div>
          )}
          {doc.construction && Object.keys(doc.construction).length > 0 && (
            <div style={{ marginTop: '5px', fontSize: '12px' }}>
              <strong>Byggeinformasjon:</strong>
              <div style={styles.documentDetail}>
                <strong>Verft:</strong> {doc.construction.shipyard || 'Ukjent'}
              </div>
              <div style={styles.documentDetail}>
                <strong>Byggenummer:</strong> {doc.construction.constructionNumber || 'Ukjent'}
              </div>
              <div style={styles.documentDetail}>
                <strong>Byggested:</strong> {doc.construction.location || 'Ukjent'}
              </div>
              <div style={styles.documentDetail}>
                <strong>Land:</strong> {doc.construction.countryDescription || 'Ukjent'}
              </div>
              <div style={styles.documentDetail}>
                <strong>Byggeår:</strong> {doc.construction.year || 'Ukjent'}
              </div>
              <div style={styles.documentDetail}>
                <strong>Modell:</strong> {doc.construction.vesselModel || 'Ukjent'}
              </div>
            </div>
          )}
          {doc.roles && doc.roles.length > 0 && (
            <div style={{ marginTop: '5px', fontSize: '12px' }}>
              <strong>Roller:</strong>
              {doc.roles.map((role, idx) => (
                <div key={idx} style={{ marginTop: '3px' }}>
                  <div style={styles.documentDetail}>
                    <strong>Type:</strong> {role.roleType || 'Ukjent'}
                  </div>
                  {role.legalEntity && (
                    <>
                      <div style={styles.documentDetail}>
                        <strong>Navn:</strong> {role.legalEntity.name || 'Ukjent'}
                      </div>
                      <div style={styles.documentDetail}>
                        <strong>Adresse:</strong> {role.legalEntity.businessAddress?.streetName || 'Ukjent'},{' '}
                        {role.legalEntity.businessAddress?.city || 'Ukjent'}
                      </div>
                      <div style={styles.documentDetail}>
                        <strong>Postnummer:</strong> {role.legalEntity.businessAddress?.zipCode || 'Ukjent'}
                      </div>
                      <div style={styles.documentDetail}>
                        <strong>Land:</strong> {role.legalEntity.businessAddress?.country || 'Ukjent'}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ));
    }
    return <div style={styles.loading}>Ingen SDIR-data funnet.</div>;
  };

  return (
    <div style={styles.container}>
      {/* Header med båtinfo */}
      <div style={styles.headerContainer}>
        <div style={styles.headerInfo}>
          <div style={styles.headerImageContainer}>
              <img
                src={shipData.img_url || DEFAULT_IMAGE_URL}
                alt={shipData.name || 'Ukjent skip'}
                style={styles.headerImage}
              />
          </div>
          <div style={styles.headerText}>
            {/* Tilbake-knappen nå plassert øverst i headeren */}
            <button style={styles.backButton} onClick={() => navigate(-1)}>
              ← Tilbake
            </button>
            <h1 style={styles.headerTitle}>{shipData.name || 'Ukjent skip'}</h1>
            <p style={styles.headerSubtitle}>
              Tidligere navn:<br />
              {shipData.prev_names || shipData.prev_names_text || 'Ingen'}
            </p>
            {/* Seksjon for systemer */}
            <div style={styles.systemsContainer}>
              {systems.map((s, idx) => (
                <div key={idx} style={styles.systemItem}>
                  PO: {s.po} | Kundenavn: {s.customer} | System: {s.system}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={styles.headerMap}>
          <MapContainer
            center={[
              shipData.latitude ? parseFloat(shipData.latitude) : 65.5,
              shipData.longitude ? parseFloat(shipData.longitude) : 13,
            ]}
            zoom={5}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              url={
                theme === 'light'
                  ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  : 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
              }
              attribution={
                theme === 'light'
                  ? '&copy; OpenStreetMap contributors'
                  : '&copy; CARTO'
              }
            />
            {shipData.latitude && shipData.longitude && (
              <Marker
                position={[
                  parseFloat(shipData.latitude),
                  parseFloat(shipData.longitude),
                ]}
                icon={createCanvasIcon('red')}
              />
            )}
          </MapContainer>
        </div>
      </div>

      {/* Hovedinnhold med to kolonner: venstre panel med utvidet skipinfo og høyre panel med SDIR-data */}
      <div style={styles.twoColumnLayout}>
        {/* Venstre panel: Utvidet skipinfo */}
        <div style={styles.panel}>
          <div style={styles.sectionTitle}>Generell informasjon</div>
          <div style={styles.infoGrid}>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>MMSI</div>
              <div style={styles.infoValue}>{shipData.mmsi || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Call Sign</div>
              <div style={styles.infoValue}>{shipData.call_sign || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>IMO</div>
              <div style={styles.infoValue}>{shipData.imo || 'Ukjent'}</div>
            </div>
          </div>

          <div style={styles.sectionTitle}>Navigasjon og dimensjoner</div>
          <div style={styles.infoGrid}>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Lengde</div>
              <div style={styles.infoValue}>{shipData.ship_length || 'Ukjent'} m</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Bredde</div>
              <div style={styles.infoValue}>{shipData.ship_width || 'Ukjent'} m</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Course</div>
              <div style={styles.infoValue}>{shipData.course_over_ground || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Speed</div>
              <div style={styles.infoValue}>{shipData.speed_over_ground?.toFixed(2) || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>True Heading</div>
              <div style={styles.infoValue}>{shipData.true_heading || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Navigational Status</div>
              <div style={styles.infoValue}>{shipData.navigational_status || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Draught</div>
              <div style={styles.infoValue}>{shipData.draught || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Rate of Turn</div>
              <div style={styles.infoValue}>{shipData.rate_of_turn || 'Ukjent'}</div>
            </div>
            {/* Faste dimensjoner for antenneposisjon */}
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Dim A</div>
              <div style={styles.infoValue}>4</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Dim B</div>
              <div style={styles.infoValue}>10</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Dim C</div>
              <div style={styles.infoValue}>3</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Dim D</div>
              <div style={styles.infoValue}>3</div>
            </div>
          </div>

          <div style={styles.sectionTitle}>Byggeinformasjon og eierinfo</div>
          <div style={styles.infoGrid}>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Verft</div>
              <div style={styles.infoValue}>{shipData.yard || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Byggenummer</div>
              <div style={styles.infoValue}>{shipData.build_number || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Hull</div>
              <div style={styles.infoValue}>{shipData.hull || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Design</div>
              <div style={styles.infoValue}>{shipData.design || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Ship Owner</div>
              <div style={styles.infoValue}>{shipData.ship_owner || 'Ukjent'}</div>
            </div>
          </div>

          <div style={styles.sectionTitle}>Diverse og tidsstempler</div>
          <div style={styles.infoGrid}>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>PMH</div>
              <div style={styles.infoValue}>{shipData.pmh || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Msg Time</div>
              <div style={styles.infoValue}>{shipData.msg_time || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Msg Time Static</div>
              <div style={styles.infoValue}>{shipData.msg_time_static || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Report Class</div>
              <div style={styles.infoValue}>{shipData.report_class || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Pos. Fix Device</div>
              <div style={styles.infoValue}>{shipData.position_fixing_device_type || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Altitude</div>
              <div style={styles.infoValue}>{shipData.altitude || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>Other</div>
              <div style={styles.infoValue}>{shipData.other || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>PMH VPN</div>
              <div style={styles.infoValue}>{shipData.pmh_vpn || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>PMH Equipment</div>
              <div style={styles.infoValue}>{shipData.pmh_equipment || 'Ukjent'}</div>
            </div>
            <div style={styles.infoItem}>
              <div style={styles.infoLabel}>PMH PO</div>
              <div style={styles.infoValue}>{shipData.pmh_po || 'Ukjent'}</div>
            </div>
          </div>

          <div style={{ textAlign: 'center' }}>
            <button style={styles.button} onClick={handleOpenProposalModal}>
              Foreslå endring
            </button>
          </div>
        </div>

        {/* Høyre panel: SDIR-data */}
        <div style={styles.panel}>
          <div style={styles.sectionTitle}>Info fra sjøfartsdirektoratet</div>
          {renderDocuments()}
        </div>
      </div>

      {/* Modal for "Foreslå endring" */}
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h2>Foreslå endring</h2>
            {loadingProposal ? (
              <div style={styles.loading}>Henter data...</div>
            ) : (
              <form onSubmit={handleSubmitProposal}>
                <div style={styles.formField}>
                  <label style={styles.label} htmlFor="name">Navn</label>
                  <input
                    style={styles.input}
                    type="text"
                    id="name"
                    name="name"
                    defaultValue={proposalData?.name || shipData.name || ''}
                  />
                </div>
                <div style={styles.formField}>
                  <label style={styles.label}>Tidligere navn</label>
                  {prevNames.map((prevName, index) => (
                    <div key={index} style={{ marginBottom: '5px' }}>
                      <input
                        style={{ ...styles.input, width: '97%' }}
                        type="text"
                        value={prevName}
                        onChange={(e) => {
                          const newPrevNames = [...prevNames];
                          newPrevNames[index] = e.target.value;
                          setPrevNames(newPrevNames);
                        }}
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    style={styles.button}
                    onClick={() => setPrevNames([...prevNames, ''])}
                  >
                    Legg til nytt tidligere navn
                  </button>
                </div>
                {/* Ytterligere felter for endringsforslag – beholdt fra tidligere */}
                <div style={{ display: 'flex', gap: '5%', marginBottom: '15px' }}>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="yard">Verft</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="yard"
                      name="yard"
                      defaultValue={proposalData?.yard || shipData.yard || ''}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="build_number">Byggenummer</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="build_number"
                      name="build_number"
                      defaultValue={proposalData?.build_number || shipData.build_number || ''}
                    />
                  </div>
                </div>
                <div style={styles.formField}>
                  <label style={styles.label} htmlFor="ship_owner">Rederi</label>
                  <input
                    style={styles.input}
                    type="text"
                    id="ship_owner"
                    name="ship_owner"
                    defaultValue={proposalData?.man_ship_owner || shipData.man_ship_owner || ''}
                  />
                </div>
                <div style={{ display: 'flex', gap: '5%', marginBottom: '15px' }}>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="imo">IMO</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="imo"
                      name="imo"
                      defaultValue={proposalData?.imo || shipData.imo || ''}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="mmsi">MMSI</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="mmsi"
                      name="mmsi"
                      defaultValue={proposalData?.mmsi || shipData.mmsi || ''}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '5%', marginBottom: '15px' }}>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="ship_length">Lengde (meter)</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="ship_length"
                      name="ship_length"
                      defaultValue={proposalData?.ship_length || shipData.ship_length || ''}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="ship_width">Bredde (meter)</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="ship_width"
                      name="ship_width"
                      defaultValue={proposalData?.ship_width || shipData.ship_width || ''}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '5%', marginBottom: '15px' }}>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="hull">Skrog</label>
                    <select
                      style={{ ...styles.input, width: '100%' }}
                      id="hull"
                      name="hull"
                      defaultValue={proposalData?.hull || 'enkelt'}
                    >
                      <option value="enkelt">Enkelt skrog</option>
                      <option value="katamaran">Katamaran</option>
                      <option value="trimaran">Trimaran</option>
                      <option value="annet">Annet</option>
                    </select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label style={styles.label} htmlFor="design">Design</label>
                    <input
                      style={{ ...styles.input, width: '95%' }}
                      type="text"
                      id="design"
                      name="design"
                      defaultValue={proposalData?.design || shipData.design || ''}
                    />
                  </div>
                </div>
                <div style={styles.formField}>
                  <label style={styles.label} htmlFor="annet">Annet</label>
                  <textarea
                    style={styles.textarea}
                    id="annet"
                    name="annet"
                    defaultValue={proposalData?.annet || ''}
                  ></textarea>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <button type="button" style={styles.button} onClick={handleCloseModal}>
                    Avbryt
                  </button>
                  <button type="submit" style={styles.button}>
                    Send inn
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipDetailScreen;
