// Tool1.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Service1 = ({ styles }) => {
  const [modules, setModules] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState('model');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' eller 'desc'

  // Hent moduler fra API-et
  /*useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await axios.get('https://pmh-intern.no:8500/api/v1/service/omron-nx-modules');
        setModules(response.data);
      } catch (error) {
        console.error('Error fetching modules:', error);
      }
    };

    fetchModules();
  }, []);*/


  useEffect(() => {
    // Funksjon for å hente data fra API-et
    const fetchStatus = async () => {
      try {

        const token = localStorage.getItem('token');
        if (!token) {
          return;
        }

        const response = await fetch(
          `https://pmh-intern.no:8500/api/v1/service/omron-nx-modules`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 403) {
          console.log('logg ut!');
          window.location.href = '/login';
        }

        const data = await response.json();
        setModules(data);

      } catch (error) {
        console.error('Error fetching server status:', error);
      }
    };
    fetchStatus();
  }, []);

  // Håndter sortering ved klikk på header
  const handleSort = (column) => {
    if (sortColumn === column) {
      // Hvis vi allerede sorterer etter denne kolonnen, bytt rekkefølge
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  // Filtrer moduler basert på søkestrengen
  let filteredModules = modules.filter((module) => {
    const query = searchQuery.toLowerCase();
    return (
      (module.model && module.model.toLowerCase().includes(query)) ||
      (module.type && module.type.toLowerCase().includes(query)) ||
      (module.input_output_range && module.input_output_range.toLowerCase().includes(query)) ||
      (module.resolution && module.resolution.toLowerCase().includes(query)) ||
      (module.conversion && module.conversion.toLowerCase().includes(query)) ||
      (module.method && module.method.toLowerCase().includes(query)) ||
      (module.datasheet && module.datasheet.toString().includes(query))
    );
  });

  // Sorter de filtrerte modulene
  filteredModules.sort((a, b) => {
    let aVal = a[sortColumn];
    let bVal = b[sortColumn];

    // Håndter null/undefined
    if (aVal === undefined || aVal === null) aVal = '';
    if (bVal === undefined || bVal === null) bVal = '';

    // Hvis verdiene er tall, sorter numerisk
    if (!isNaN(aVal) && !isNaN(bVal)) {
      return sortOrder === 'asc' ? aVal - bVal : bVal - aVal;
    }

    // Ellers sorter som tekst
    aVal = aVal.toString().toLowerCase();
    bVal = bVal.toString().toLowerCase();
    if (aVal < bVal) return sortOrder === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  // Definer tabell-headere (ID er fjernet)
  const headers = [
    { key: 'model', label: 'Model' },
    { key: 'type', label: 'Type' },
    { key: 'number_of_points', label: 'Antall punkter' },
    { key: 'input_output_range', label: 'Input/Output Range' },
    { key: 'resolution', label: 'Oppløsning' },
    { key: 'conversion', label: 'Conversion' },
    { key: 'method', label: 'Metode' },
    { key: 'datasheet', label: 'Datasheet' },
  ];

  return (
    <div style={styles.contentContainer}>
      <h2>Omron NX Moduler</h2>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          placeholder="Søk etter moduler..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={styles.searchInput}
        />
      </div>
      {filteredModules.length > 0 ? (
        <div style={styles.tableContainer}>
          <table style={styles.table}>
            <thead>
              <tr>
                {headers.map((header) => (
                  <th
                    key={header.key}
                    style={styles.th}
                    onClick={() => handleSort(header.key)}
                  >
                    {header.label}
                    {sortColumn === header.key && (sortOrder === 'asc' ? ' ▲' : ' ▼')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredModules.map((module, index) => (
                <tr key={module.id} style={index % 2 === 0 ? styles.trEven : styles.trOdd}>
                  <td style={styles.td}>{module.model}</td>
                  <td style={styles.td}>{module.type}</td>
                  <td style={styles.td}>{module.number_of_points}</td>
                  <td style={styles.td}>{module.input_output_range}</td>
                  <td style={styles.td}>{module.resolution}</td>
                  <td style={styles.td}>{module.conversion}</td>
                  <td style={styles.td}>{module.method}</td>
                  <td style={styles.td}>
                    <a 
                      href={`https://assets.omron.eu/downloads/latest/datasheet/en/nx-series_analog_i_o_unit_-_nx-ad_da_datasheet_en.pdf#page=${module.datasheet}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                      {module.datasheet}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Ingen moduler funnet.</p>
      )}
    </div>
  );
};

export default Service1;
