import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useNavigate, useParams, Link } from 'react-router-dom';

const DEFAULT_IMAGE_URL = 'https://pmh-intern.no/default.webp';

const YardListScreen = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  // Vi forventer at ruten er definert som "/yard-list/:yard"
  const { yard: urlYard } = useParams();

  const [loading, setLoading] = useState(false);
  const [ships, setShips] = useState([]);
  const [yards, setYards] = useState([]); // Alle unike verft
  const [filteredYards, setFilteredYards] = useState([]);
  const [selectedYard, setSelectedYard] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const styles = getStyles(theme);

  // --- Hent alle skip fra API (bruker "all_ships") ---
  useEffect(() => {
    const fetchAllShips = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          // Håndter manglende innlogging
          return;
        }

        const response = await fetch(
          'https://pmh-intern.no:8500/api/v1/ship/info?shipFilter=all_ships',
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 403) {
          console.log('logg ut!');
          window.location.href = '/login';
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data) {
          const shipArray = Array.isArray(data) ? data : Object.values(data);
          setShips(shipArray);

          // Hent ut alle verft og filtrer bort tomme strenger
          const allYards = shipArray
            .map((ship) => ship.yard)
            .filter((yard) => yard && yard.trim().length > 0);

          // Lag en liste over unike verft, sortert alfabetisk
          const uniqueSortedYards = Array.from(new Set(allYards)).sort((a, b) =>
            a.localeCompare(b)
          );

          setYards(uniqueSortedYards);
          setFilteredYards(uniqueSortedYards);
        }
      } catch (error) {
        console.error('Feil ved henting av skip:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllShips();
  }, []);

  // --- Oppdater filteredYards når brukeren skriver i søkefeltet ---
  useEffect(() => {
    if (!searchQuery) {
      setFilteredYards(yards);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = yards.filter((yard) =>
        yard.toLowerCase().includes(lowerQuery)
      );
      setFilteredYards(filtered);
    }
  }, [searchQuery, yards]);

  // --- Sett valgt verft basert på URL-parameter ---
  useEffect(() => {
    console.log("kjører")
    console.log(urlYard)
    console.log(yards.length)
    if (urlYard && yards.length > 0) {
      const decodedYard = decodeURIComponent(urlYard).trim().toLowerCase();
      console.log("Decoded yard fra URL:", decodedYard);
      console.log("Liste over verft:", yards.map(y => y.trim().toLowerCase()));
      const matchingYard = yards.find(
        (yard) => yard.trim().toLowerCase() === decodedYard
      );
      if (matchingYard) {
        setSelectedYard(matchingYard);
      } else {
        setSelectedYard(null);
      }
    }
  }, [urlYard, yards]);


  // --- Filtrer skip basert på valgt verft ---
  const shipsForSelectedYard = selectedYard
    ? ships.filter((ship) => ship.yard === selectedYard)
    : [];

  // --- Sorter skipene etter stigende byggenummer ---
  const sortedShipsForSelectedYard = shipsForSelectedYard.sort((a, b) => {
    const buildNumA = parseInt(a.build_number, 10) || 0;
    const buildNumB = parseInt(b.build_number, 10) || 0;
    return buildNumA - buildNumB;
  });

  // --- Håndter klikk på verft i venstremeny ---
  const handleYardClick = (yard) => {
    setSelectedYard(yard);
    navigate(`/yard-list/${encodeURIComponent(yard)}`);
  };

  return (
    <div style={styles.container}>
      {/* Venstre side - liste over verft */}
      <div style={styles.sidebar}>
        <h2 style={styles.sidebarTitle}>Verft</h2>
        <input
          type="text"
          placeholder="Søk verft..."
          style={styles.searchInput}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div style={styles.yardList} className="yard-scrollbar">
          {loading ? (
            <div style={styles.loading}>Laster verft...</div>
          ) : filteredYards.length > 0 ? (
            filteredYards.map((yard) => (
              <div
                key={yard}
                style={{
                  ...styles.yardItem,
                  ...(yard === selectedYard ? styles.yardItemSelected : {}),
                }}
                onClick={() => handleYardClick(yard)}
              >
                {yard}
              </div>
            ))
          ) : (
            <div style={styles.noYardsFound}>Ingen verft funnet</div>
          )}
        </div>
      </div>

      {/* Høyre side - grid av skip-kort fra valgt verft */}
      <div style={styles.mainContent}>
        {selectedYard ? (
          <>
            <h2 style={styles.mainTitle}>Skip bygget av: {selectedYard}</h2>
            {sortedShipsForSelectedYard.length > 0 ? (
              <div style={styles.shipsGrid}>
                {sortedShipsForSelectedYard.map((ship) => (
                  <Link
                    key={ship.mmsi}
                    to={`/ship/${ship.mmsi}`}
                    style={styles.shipItem}
                  >
                    <img
                      src={ship.img_url || DEFAULT_IMAGE_URL}
                      alt={ship.name || 'Ukjent skip'}
                      style={styles.shipImage}
                    />
                    <div style={styles.shipInfoContainer}>
                      <h3 style={styles.shipName}>
                        {ship.name || 'Ukjent skip'}
                      </h3>
                      <p style={styles.shipInfo}>
                        <b>Byggenr:</b> {ship.build_number || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>MMSI:</b> {ship.mmsi || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Design:</b> {ship.design || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Lengde/Bredde:</b>{' '}
                        {(ship.ship_length || 'Ukjent') +
                          ' / ' +
                          (ship.ship_width || 'Ukjent')}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Kjennesignal:</b> {ship.call_sign || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Rederi:</b> {ship.ship_owner || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Tidligere navn:</b> {ship.prev_names || 'Ingen'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Byggeår:</b> {ship.build_year || 'Ukjent'}
                      </p>
                      <p style={styles.shipInfo}>
                        <b>Andre stikkord:</b> {ship.keywords || 'Ingen'}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div style={styles.noShipsFound}>
                Ingen skip funnet for dette verftet
              </div>
            )}
          </>
        ) : (
          <div style={styles.noYardSelected}>
            <h2 style={styles.mainTitle}>
              Velg et verft fra menyen til venstre
            </h2>
          </div>
        )}
      </div>

      {/* Global CSS for scrollbars */}
      <style jsx>{`
        .yard-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: ${theme === 'light'
            ? '#aaa #f0f0f0'
            : '#666 #2e2e2e'};
        }
        .yard-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .yard-scrollbar::-webkit-scrollbar-track {
          background: ${theme === 'light' ? '#f0f0f0' : '#2e2e2e'};
          border-radius: 10px;
        }
        .yard-scrollbar::-webkit-scrollbar-thumb {
          background-color: ${theme === 'light' ? '#ccc' : '#666'};
          border-radius: 10px;
          border: 2px solid transparent;
        }
        .yard-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: ${theme === 'light' ? '#999' : '#444'};
        }
      `}</style>
    </div>
  );
};

export default YardListScreen;

// --- CSS-in-JS styling ---
const getStyles = (theme) => {
  const isLight = theme === 'light';

  return {
    container: {
      display: 'flex',
      height: 'calc(100vh - 71px)',
      backgroundColor: isLight ? '#f5f5f5' : '#2e2e2e',
      color: isLight ? '#000000' : '#ffffff',
      overflow: 'hidden',
    },
    sidebar: {
      width: '300px',
      minWidth: '300px',
      backgroundColor: isLight ? '#ffffff' : '#3a3a3a',
      borderRight: isLight ? '1px solid #ccc' : '1px solid #555',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
    },
    sidebarTitle: {
      margin: '0 0 10px',
      fontSize: '18px',
      borderBottom: isLight ? '1px solid #ccc' : '1px solid #555',
      paddingBottom: '10px',
    },
    searchInput: {
      padding: '8px',
      borderRadius: '4px',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      backgroundColor: isLight ? '#ffffff' : '#555555',
      color: isLight ? '#000000' : '#ffffff',
      marginBottom: '10px',
    },
    yardList: {
      flex: 1,
      overflowY: 'auto',
      marginTop: '10px',
    },
    yardItem: {
      padding: '10px',
      borderRadius: '4px',
      marginBottom: '5px',
      backgroundColor: isLight ? '#f5f5f5' : '#444444',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s',
    },
    yardItemSelected: {
      backgroundColor: isLight ? '#d0e4ff' : '#0066cc',
      color: '#ffffff',
    },
    noYardsFound: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '14px',
    },
    loading: {
      textAlign: 'center',
      fontSize: '14px',
    },
    mainContent: {
      flex: 1,
      padding: '20px',
      overflowY: 'auto',
    },
    mainTitle: {
      fontSize: '18px',
      margin: 0,
      marginBottom: '20px',
      borderBottom: isLight ? '1px solid #ccc' : '1px solid #555',
      paddingBottom: '10px',
    },
    noYardSelected: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    shipsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
      gap: '20px',
    },
    shipItem: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: isLight ? '#ffffff' : '#444444',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      borderRadius: '5px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      overflow: 'hidden',
      textDecoration: 'none',
    },
    shipImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
    },
    shipInfoContainer: {
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    shipName: {
      margin: 0,
      marginBottom: '8px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: isLight ? '#000000' : '#ffffff',
    },
    shipInfo: {
      margin: 0,
      fontSize: '13px',
      color: isLight ? '#666666' : '#aaaaaa',
      lineHeight: '1.4em',
    },
    noShipsFound: {
      marginTop: '20px',
      fontSize: '14px',
      textAlign: 'center',
    },
  };
};
