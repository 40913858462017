import React, { useState, useEffect } from 'react';

// Komponent for visning av en enkelt endringspost med nåværende verdi
const ChangeLogItem = ({ changeData, onFieldApprove, onStatusChange }) => {
  // Liste over feltene vi ønsker å sammenligne
  const fields = [
    { key: 'call_sign', label: 'Call Sign' },
    { key: 'yard', label: 'Yard' },
    { key: 'build_number', label: 'Build Number' },
    { key: 'ship_owner', label: 'Ship Owner' },
    { key: 'build_year', label: 'Build Year' },
    { key: 'design', label: 'Design' },
    { key: 'imo', label: 'IMO' },
    { key: 'name', label: 'Name' },
  ];

  return (
    <div className="changelog-item">
      <h2>Endringslogg for skip ID: {changeData.sdir_id}</h2>
      <p>
        Endret: {new Date(changeData.changed_at).toLocaleString()} | Status: <span className="status-text">{changeData.status}</span>
      </p>
      <table className="change-table">
        <thead>
          <tr>
            <th>Felt</th>
            <th>Gammel verdi</th>
            <th>Ny verdi</th>
            <th>Nåværende verdi</th>
            <th>Handling</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field) => {
            const oldKey = 'old_' + field.key;
            const newKey = 'new_' + field.key;
            const currentKey = 'current_' + field.key;
            return (
              <tr key={field.key}>
                <td>{field.label}</td>
                <td>{changeData[oldKey] !== null ? changeData[oldKey] : '-'}</td>
                <td>{changeData[newKey] !== null ? changeData[newKey] : '-'}</td>
                <td>{changeData[currentKey] !== null ? changeData[currentKey] : '-'}</td>
                <td>
                  <button
                    className="action-button"
                    onClick={() => onFieldApprove(field.key, changeData[newKey])}
                  >
                    Godkjenn
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="status-buttons">
        <p>Endre status for hele endringen:</p>
        <div className="button-group">
          <button className="action-button status-accept" onClick={() => onStatusChange('Ferdig')}>
            Ferdig
          </button>
          <button className="action-button status-deny" onClick={() => onStatusChange('Vis senere')}>
            Vis senere
          </button>
          <button className="action-button status-cancel" onClick={() => onStatusChange('Fjern')}>
            Fjernet
          </button>
        </div>
      </div>
    </div>
  );
};

const ChangeLogPage = () => {
  const [changeLogs, setChangeLogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filterStatus, setFilterStatus] = useState("new"); // Standard: Nye
  const [loading, setLoading] = useState(true);

  // Hent endringsdata fra API-et
  const fetchChangeLogs = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }
      const response = await fetch('https://pmh-intern.no:8500/api/v1/sdir/change-log', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 403) {
        window.location.href = '/login';
        return;
      }
      const data = await response.json();
      setChangeLogs(data);
      setLoading(false);
    } catch (error) {
      console.error('Feil ved henting av endringsdata:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChangeLogs();
  }, []);

  // Filtrer data basert på filterStatus
  const filteredLogs = changeLogs.filter(item => {
    if (filterStatus === "Alle") return true;
    if (filterStatus === "new") return item.status === "new";
    if (filterStatus === "Ferdig") return item.status === "Ferdig";
    if (filterStatus === "Vis senere") return item.status === "Vis senere";
    if (filterStatus === "slettet") return item.status === "Fjern"; // "Fjern" tolkes som slettet
    return true;
  });

  const currentChange = filteredLogs[currentIndex];

  // Navigasjonsfunksjoner
  const handleNext = () => {
    if (currentIndex < filteredLogs.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Endre filteret og resette currentIndex
  const handleFilterChange = (newFilter) => {
    setFilterStatus(newFilter);
    setCurrentIndex(0);
  };

  // Funksjon for å godkjenne et felt
  const handleFieldApprove = async (field, newValue) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pmh-intern.no:8500/api/v1/sdir/change-log/approve-field', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: currentChange.sdir_id,  // Bruker sdir_id som fellesnevner
          field,
          newValue
        })
      });
      const data = await response.json();
      console.log('Felt godkjent:', data);
      if (data.updatedRow) {
        // Oppdater lokalt med de nye "current"-verdiene
        setChangeLogs(prevLogs => {
          const newLogs = [...prevLogs];
          newLogs[currentIndex] = {
            ...newLogs[currentIndex],
            current_call_sign: data.updatedRow.call_sign,
            current_yard: data.updatedRow.yard,
            current_build_number: data.updatedRow.build_number,
            current_ship_owner: data.updatedRow.ship_owner,
            current_build_year: data.updatedRow.build_year,
            current_design: data.updatedRow.design,
            current_imo: data.updatedRow.imo,
            current_name: data.updatedRow.name
          };
          return newLogs;
        });
      }
      // Hent ferske data fra backend for å være helt sikker
      fetchChangeLogs();
    } catch (error) {
      console.error('Feil ved godkjenning av felt:', error);
    }
  };

  // Funksjon for å endre status: sender ny status til backend
  const handleStatusChange = async (newStatus) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://pmh-intern.no:8500/api/v1/sdir/change-log/change-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: currentChange.id,
          status: newStatus
        })
      });
      const data = await response.json();
      console.log('Status oppdatert:', data);
      // Hent ferske data etter statusendringen slik at UI oppdateres riktig
      fetchChangeLogs();
    } catch (error) {
      console.error('Feil ved oppdatering av status:', error);
    }
  };

  return (
    <>
      {/* Innebygd CSS */}
      <style>{`
        body {
          background-color: #121212;
          color: #e0e0e0;
          margin: 0;
          padding: 0;
        }
        .content-container {
          padding: 40px;
          max-width: 1200px;
          margin: 0 auto;
          text-align: center;
        }
        h1 {
          font-size: 2.5rem;
          margin-bottom: 20px;
          color: #ffffff;
        }
        h2 {
          font-size: 1.8rem;
          margin-bottom: 10px;
          color: #ffffff;
        }
        p {
          font-size: 1rem;
          margin: 10px 0;
        }
        .changelog-item {
          background-color: #1e1e1e;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0,0,0,0.5);
          margin-bottom: 30px;
        }
        .change-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        .change-table th, .change-table td {
          border: 1px solid #333;
          padding: 12px 15px;
          text-align: left;
        }
        .change-table th {
          background-color: #333;
          color: #fff;
          font-weight: bold;
        }
        .action-button {
          padding: 8px 16px;
          margin: 4px;
          border: none;
          border-radius: 4px;
          background-color: #007BFF;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s ease, transform 0.2s ease;
          font-size: 0.9rem;
        }
        .action-button:hover {
          background-color: #0056b3;
          transform: scale(1.05);
        }
        .status-buttons {
          margin-top: 20px;
        }
        .button-group {
          display: flex;
          justify-content: center;
          gap: 10px;
          flex-wrap: wrap;
        }
        .status-accept {
          background-color: #28a745;
        }
        .status-deny {
          background-color: #dc3545;
        }
        .status-cancel {
          background-color: #ffc107;
          color: #333;
        }
        .nav-buttons {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
        .toggle-buttons {
          margin-bottom: 30px;
        }
        .toggle-button {
          padding: 12px 20px;
          border: none;
          border-radius: 5px;
          background-color: #007BFF;
          color: #fff;
          cursor: pointer;
          font-size: 1rem;
          margin: 0 10px;
          transition: background-color 0.3s ease;
        }
        .toggle-button:disabled {
          background-color: #555;
          cursor: default;
        }
        @media (prefers-color-scheme: dark) {
          body {
            background-color: #121212;
            color: #e0e0e0;
          }
          .change-table th {
            background-color: #1e1e1e;
          }
        }
      `}</style>

      <div className="content-container">
        <h1>Endringslogg</h1>
        <div className="toggle-buttons">
          <button
            className="toggle-button"
            onClick={() => handleFilterChange("Alle")}
            disabled={filterStatus === "Alle"}
          >
            Vis alle
          </button>
          <button
            className="toggle-button"
            onClick={() => handleFilterChange("new")}
            disabled={filterStatus === "new"}
          >
            Nye
          </button>
          <button
            className="toggle-button"
            onClick={() => handleFilterChange("Ferdig")}
            disabled={filterStatus === "Ferdig"}
          >
            Ferdig
          </button>
          <button
            className="toggle-button"
            onClick={() => handleFilterChange("Vis senere")}
            disabled={filterStatus === "Vis senere"}
          >
            Vis senere
          </button>
          <button
            className="toggle-button"
            onClick={() => handleFilterChange("slettet")}
            disabled={filterStatus === "slettet"}
          >
            Slettet
          </button>
        </div>
        {loading ? (
          <p>Laster endringsdata...</p>
        ) : filteredLogs.length === 0 ? (
          <p>Ingen endringsdata funnet for valgt filter.</p>
        ) : (
          <>
            <ChangeLogItem
              changeData={currentChange}
              onFieldApprove={handleFieldApprove}
              onStatusChange={handleStatusChange}
            />
            <div className="nav-buttons">
              <button
                className="action-button"
                onClick={handlePrevious}
                disabled={currentIndex === 0}
              >
                Forrige
              </button>
              <span>
                {currentIndex + 1} / {filteredLogs.length}
              </span>
              <button
                className="action-button"
                onClick={handleNext}
                disabled={currentIndex === filteredLogs.length - 1}
              >
                Neste
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ChangeLogPage;
