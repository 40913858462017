import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';
import { MapContainer, TileLayer, Marker, Tooltip, LayerGroup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-rotatedmarker';
import { useSearchParams } from 'react-router-dom';
import './MapScreen.css';

// 1) En enkel debounce-hook
function useDebounce(value, delay = 10) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

// 2) Hjelpekomponent for å hente kartet via useMap()
// Sender kartinstansen opp med setMapInstance
function MapController({ setMapInstance }) {
  const map = useMap();
  useEffect(() => {
    setMapInstance(map);
  }, [map, setMapInstance]);
  return null;
}

// Global cache for canvas-ikoner
const iconCache = {};

// Opprett et canvas-ikon med caching
function createCanvasIcon(color, stale = false) {
  const cacheKey = color + (stale ? '_stale' : '');
  if (iconCache[cacheKey]) return iconCache[cacheKey];

  const width = 16;
  const height = 20;
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  // Tegn hovedikonet
  ctx.fillStyle = color;
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 0.8;
  ctx.beginPath();
  ctx.moveTo(width / 2, 0);
  ctx.lineTo(width, height);
  ctx.lineTo(width / 2, height * 0.8);
  ctx.lineTo(0, height);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();

  // Hvis posisjonen er stale, tegn en ekstra ramme
  if (stale) {
    ctx.fillStyle = 'orange';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 0.8;
    ctx.beginPath();
    ctx.moveTo(width / 2, 0);
    ctx.lineTo(width, height);
    ctx.lineTo(width / 2, height * 0.8);
    ctx.lineTo(0, height);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  } else {
    ctx.fillStyle = color;
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 0.8;
    ctx.beginPath();
    ctx.moveTo(width / 2, 0);
    ctx.lineTo(width, height);
    ctx.lineTo(width / 2, height * 0.8);
    ctx.lineTo(0, height);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  const icon = L.icon({
    iconUrl: canvas.toDataURL(),
    iconSize: [width, height],
    iconAnchor: [width / 2, height / 2],
  });
  iconCache[cacheKey] = icon;
  return icon;
}

// Returner farge basert på skrog og om skipet er aktivt
function getColorForHull(hull, isActive) {
  if (isActive) return '#ff0000';
  switch (hull) {
    case 0:
      return '#34ebeb';
    case 1:
      return '#4e73df';
    case 2:
      return '#1cc88a';
    case 3:
      return '#36b9cc';
    default:
      return '#6c757d';
  }
}

// Hovedkomponent
const MapScreen = () => {
  const { theme } = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  // Bruk URL-parametre for å lagre kartposisjon og zoom
  const [searchParams, setSearchParams] = useSearchParams();
  const initialLat = parseFloat(searchParams.get('lat')) || 65.5;
  const initialLng = parseFloat(searchParams.get('lng')) || 13;
  const initialZoom = parseInt(searchParams.get('zoom'), 10) || 5;

  // Kart- og skipstater
  const [shipPositions, setShipPositions] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [detailView, setDetailView] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);

  // Søk
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedQuery = useDebounce(searchQuery, 10);
  const [searchRegex, setSearchRegex] = useState(null);

  // Initialiser searchOptions med verdier fra localStorage (hvis de finnes)
  const [searchOptions, setSearchOptions] = useState(() => {
    const savedOptions = localStorage.getItem('searchOptions');
    return savedOptions
      ? JSON.parse(savedOptions)
      : {
          name: true,
          mmsi: true,
          callSign: true,
          imo: true,
          yard: true,
          buildNumber: true,
          shipOwner: true,
        };
  });

  // Lagre searchOptions i localStorage hver gang de endres
  useEffect(() => {
    localStorage.setItem('searchOptions', JSON.stringify(searchOptions));
  }, [searchOptions]);

  // State for å vise/skjule menyen med søkealternativer
  const [showSearchOptions, setShowSearchOptions] = useState(false);

  // Håndter endring i søkealternativer
  const handleSearchOptionChange = (option) => {
    setSearchOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  useEffect(() => {
    if (!debouncedQuery) {
      setSearchRegex(null);
      return;
    }
    const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    let regexStr = '';
    for (const char of debouncedQuery) {
      if (char === 'ø' || char === 'o') {
        regexStr += '(?:o|ø|oe)';
      } else if (char === 'å' || char === 'a') {
        regexStr += '(?:a|å|aa)';
      } else if (char === 'æ') {
        regexStr += '(?:æ|a|e|ae)';
      } else {
        regexStr += escapeRegex(char);
      }
    }
    const regex = new RegExp(regexStr, 'i');
    setSearchRegex(regex);
  }, [debouncedQuery]);

  // Hent skipenes posisjoner (kun én gang)
  useEffect(() => {
    const fetchShipData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;
        const response = await fetch(`https://pmh-intern.no:8500/api/v1/ship/position`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 403) {
          console.log('logg ut!');
          window.location.href = '/login';
          return;
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const validShips = Object.values(data).filter(
          (ship) => ship.latitude && ship.longitude
        );
        setShipPositions(validShips);
        setLoading(false);
      } catch (error) {
        console.error('Feil ved henting av skipets posisjoner:', error);
        setLoading(false);
      }
    };
    fetchShipData();
  }, []);

  // Filtrer skip basert på søk og valgte søkealternativer
  const filteredShips = useMemo(() => {
    if (!searchRegex) return shipPositions;
    return shipPositions.filter((ship) => {
      let matches = false;
      if (searchOptions.mmsi && ship.mmsi && searchRegex.test(ship.mmsi.toString())) {
        matches = true;
      }
      if (searchOptions.callSign && ship.call_sign && searchRegex.test(ship.call_sign)) {
        matches = true;
      }
      if (searchOptions.name) {
        const nameMatch =
          (ship.name && searchRegex.test(ship.name)) ||
          (ship.prev_names &&
            searchRegex.test(Array.isArray(ship.prev_names) ? ship.prev_names.join(' ') : ship.prev_names));
        if (nameMatch) matches = true;
      }
      if (searchOptions.imo && ship.imo && searchRegex.test(ship.imo.toString())) {
        matches = true;
      }
      if (searchOptions.yard && ship.yard && searchRegex.test(ship.yard)) {
        matches = true;
      }
      if (searchOptions.buildNumber && ship.build_number && searchRegex.test(ship.build_number.toString())) {
        matches = true;
      }
      if (searchOptions.shipOwner && ship.ship_owner && searchRegex.test(ship.ship_owner)) {
        matches = true;
      }
      return matches;
    });
  }, [searchRegex, shipPositions, searchOptions]);

  // URL-oppdatering: Når kartet beveger seg, oppdater URL med senter og zoom
  useEffect(() => {
    if (!mapInstance) return;
    const onMoveEnd = () => {
      const center = mapInstance.getCenter();
      const zoom = mapInstance.getZoom();
      setSearchParams((prevParams) => ({
        ...Object.fromEntries(prevParams),
        lat: center.lat.toFixed(5),
        lng: center.lng.toFixed(5),
        zoom,
      }));
    };
    mapInstance.on('moveend', onMoveEnd);
    return () => {
      mapInstance.off('moveend', onMoveEnd);
    };
  }, [mapInstance, setSearchParams]);

  // Sjekkede skip (basert på MMSI)
  const [checkedShipMMSIs, setCheckedShipMMSIs] = useState([]);
  useEffect(() => {
    if (shipPositions.length > 0) {
      setCheckedShipMMSIs(shipPositions.map((ship) => ship.mmsi));
    }
  }, [shipPositions]);

  const handleCheckboxChange = (mmsi, isChecked) => {
    setCheckedShipMMSIs((prev) => {
      if (isChecked) {
        if (!prev.includes(mmsi)) return [...prev, mmsi];
        return prev;
      } else {
        return prev.filter((id) => id !== mmsi);
      }
    });
  };

  const handleSelectAll = () => {
    const allIds = filteredShips.map((ship) => ship.mmsi);
    setCheckedShipMMSIs(allIds);
  };

  const handleDeselectAll = () => {
    setCheckedShipMMSIs([]);
  };

  // Håndter klikk på markør i kartet
  const handleMarkerClick = useCallback((ship) => {
    setActiveMarker(ship);
    setDetailView(true);
  }, []);

  // Lag markører (kun for sjekkede skip)
  const markers = useMemo(() => {
    return shipPositions
      .filter((ship) => checkedShipMMSIs.includes(ship.mmsi))
      .map((ship) => {
        const heading = ship.true_heading != null ? ship.true_heading : ship.course_over_ground;
        const rotation = heading ? parseFloat(heading) : 0;
        const isActive = activeMarker && activeMarker.mmsi === ship.mmsi;
        const color = getColorForHull(ship.hull, isActive);
        const isStale = ship.msg_time ? Date.now() - new Date(ship.msg_time) > 5 * 24 * 60 * 60 * 1000 : false;
        const icon = createCanvasIcon(color, isStale);
        return (
          <Marker
            key={ship.mmsi}
            position={[ship.latitude, ship.longitude]}
            icon={icon}
            rotationAngle={rotation}
            eventHandlers={{ click: () => handleMarkerClick(ship) }}
          >
            <Tooltip direction="top" offset={[0, -20]} opacity={1}>
              <div style={{ background: '#fff', color: '#000', padding: '5px', borderRadius: '4px' }}>
                <strong>{ship.name || 'Ukjent navn'}</strong>
                <br />
                {ship.latitude}, {ship.longitude}
                <br />
                {ship.msg_time
                  ? `Sist posisjon: ${new Date(ship.msg_time).toLocaleString()}`
                  : 'Ukjent tidspunkt'}
              </div>
            </Tooltip>
          </Marker>
        );
      });
  }, [shipPositions, checkedShipMMSIs, activeMarker, handleMarkerClick]);

  // Handlingsknapper i listen
  const handleInfoClick = (ship, e) => {
    e.stopPropagation();
    setActiveMarker(ship);
    setDetailView(true);
  };

  const handleShowOnMapClick = (ship, e) => {
    e.stopPropagation();
    if (mapInstance) {
      mapInstance.flyTo([ship.latitude, ship.longitude], 12);
    }
  };

  // Hovedboksen er ikke klikkbar – kun kontrollene er aktive
  const handleShipClick = useCallback((ship) => {
    // Her kan du evt. implementere ytterligere logikk
  }, []);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <div className="spinner">Laster...</div>
      </div>
    );
  }

  return (
    <>
      <div style={styles.rootContainer}>
        {/* Venstre spalte */}
        <div style={styles.leftContainer}>
          {detailView ? (
            <MemoizedDetailView ship={activeMarker} onBack={() => setDetailView(false)} theme={theme} />
          ) : (
            <div style={styles.listContainer}>
              <h3 style={styles.title}>Alle skip</h3>
              <input
                style={styles.searchInput}
                placeholder="Søk... (navn, MMSI, kallesignal, IMO, rederi...)"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {/* Toggle for søkealternativer */}
              <div style={styles.searchOptionsContainer}>
                <div
                  style={styles.searchOptionsToggle}
                  onClick={() => setShowSearchOptions(!showSearchOptions)}
                >
                  {showSearchOptions ? 'Skjul søkealternativer' : 'Vis søkealternativer'}
                </div>
                {showSearchOptions && (
                  <div style={styles.searchOptionsPanel}>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.name}
                        onChange={() => handleSearchOptionChange('name')}
                      />{' '}
                      Navn
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.mmsi}
                        onChange={() => handleSearchOptionChange('mmsi')}
                      />{' '}
                      MMSI
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.callSign}
                        onChange={() => handleSearchOptionChange('callSign')}
                      />{' '}
                      Callsign
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.imo}
                        onChange={() => handleSearchOptionChange('imo')}
                      />{' '}
                      IMO
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.yard}
                        onChange={() => handleSearchOptionChange('yard')}
                      />{' '}
                      Verft
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.buildNumber}
                        onChange={() => handleSearchOptionChange('buildNumber')}
                      />{' '}
                      Byggenummer
                    </label>
                    <label style={styles.searchOptionLabel}>
                      <input
                        type="checkbox"
                        checked={searchOptions.shipOwner}
                        onChange={() => handleSearchOptionChange('shipOwner')}
                      />{' '}
                      Rederi
                    </label>
                  </div>
                )}
              </div>
              {/* Knappegruppe for "Velg alle" og "Fjern alle" */}
              <div style={styles.selectionButtonsContainer}>
                <button style={styles.selectionButton} onClick={handleSelectAll}>
                  Velg alle
                </button>
                <button style={styles.selectionButton} onClick={handleDeselectAll}>
                  Fjern alle
                </button>
              </div>
              {/* Liste over skip */}
              <div style={styles.shipList} className={`custom-scrollbar ${theme === 'light' ? 'light-mode' : 'dark-mode'}`}>
                {filteredShips.length === 0 && <div style={styles.noShipsFound}>Ingen skip funnet</div>}
                {filteredShips.map((ship) => (
                  <div
                    key={ship.mmsi}
                    style={{
                      ...styles.shipListItem,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={checkedShipMMSIs.includes(ship.mmsi)}
                      onChange={(e) => handleCheckboxChange(ship.mmsi, e.target.checked)}
                      onClick={(e) => e.stopPropagation()}
                      style={{ marginRight: '8px' }}
                    />
                    <div style={styles.shipInfoContainer} onClick={handleShipClick.bind(null, ship)}>
                      <strong>{ship.name || 'Ukjent navn'}</strong>
                      <div style={{ fontSize: '12px', color: theme === 'light' ? '#666' : '#bbb' }}>
                        MMSI: {ship.mmsi || '–'}
                      </div>
                    </div>
                    <div style={styles.iconsContainer}>
                      <button style={styles.infoButton} onClick={(e) => handleInfoClick(ship, e)}>
                        i
                      </button>
                      <button style={styles.mapButton} onClick={(e) => handleShowOnMapClick(ship, e)}>
                        📍
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Kartet */}
        <div style={styles.mapContainer}>
          <MapContainer center={[initialLat, initialLng]} zoom={initialZoom} style={styles.map}>
            <MapController setMapInstance={setMapInstance} />
            <TileLayer
              url={
                theme === 'light'
                  ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  : 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
              }
              attribution={
                theme === 'light'
                  ? '&copy; OpenStreetMap contributors'
                  : '&copy; CARTO'
              }
              keepBuffer={6}
            />
            <LayerGroup>{markers}</LayerGroup>
          </MapContainer>
        </div>
      </div>
      {/* Global CSS for scrollbars og tilpassede checkbokser */}
      <style jsx>{`
        .custom-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: ${theme === 'light' ? '#aaa #f0f0f0' : '#666 #2e2e2e'};
        }
        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: ${theme === 'light' ? '#f0f0f0' : '#2e2e2e'};
          border-radius: 10px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background-color: ${theme === 'light' ? '#ccc' : '#666'};
          border-radius: 10px;
          border: 2px solid transparent;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: ${theme === 'light' ? '#999' : '#444'};
        }
        .custom-checkbox {
          width: 24px;
          height: 24px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: 2px solid ${theme === 'light' ? '#4e73df' : '#aaa'};
          border-radius: 4px;
          cursor: pointer;
          outline: none;
        }
        .custom-checkbox:checked {
          background-color: ${theme === 'light' ? '#4e73df' : '#aaa'};
          border-color: ${theme === 'light' ? '#4e73df' : '#aaa'};
        }
        .custom-checkbox:checked::after {
          content: '✓';
          display: block;
          color: white;
          font-size: 16px;
          text-align: center;
          line-height: 24px;
        }
      `}</style>
    </>
  );
};

const DetailView = ({ ship, onBack, theme }) => {
  const styles = getStyles(theme);
  if (!ship) {
    return (
      <div style={styles.detailContainer}>
        <button style={styles.backButton} onClick={onBack}>
          ← Tilbake
        </button>
        <div>Ingen skip valgt</div>
      </div>
    );
  }
  return (
    <div style={styles.detailContainer}>
      <button style={styles.backButton} onClick={onBack}>
        ← Tilbake
      </button>
      <h3 style={styles.detailHeader}>{ship.name}</h3>
      <div style={styles.infoRow}>
        <span style={styles.infoLabel}>Tidligere båtnavn:</span>
        <span style={styles.infoValue}>
          {Array.isArray(ship.prev_names) ? ship.prev_names.join(', ') : '-'}
        </span>
      </div>
      <div style={styles.infoRow}>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>MMSI:</span>
          <span style={styles.infoValue}>{ship.mmsi || '-'}</span>
        </div>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>Kallesignal:</span>
          <span style={styles.infoValue}>{ship.call_sign || '-'}</span>
        </div>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>IMO:</span>
          <span style={styles.infoValue}>{ship.imo || '-'}</span>
        </div>
      </div>
      <div style={styles.infoRow}>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Verft:</span>
          <span style={styles.infoValue}>{ship.yard || '-'}</span>
        </div>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Bygg nr.:</span>
          <span style={styles.infoValue}>{ship.build_number || '-'}</span>
        </div>
        <div style={styles.infoCol1}>
          <span style={styles.infoLabel}>Rederi:</span>
          <span style={styles.infoValue}>{ship.ship_owner || '-'}</span>
        </div>
      </div>
      <div style={styles.infoRow}>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Skrog:</span>
          <span style={styles.infoValue}>{ship.hull_type || '-'}</span>
        </div>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Design:</span>
          <span style={styles.infoValue}>{ship.design || '-'}</span>
        </div>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Lengde:</span>
          <span style={styles.infoValue}>{ship.ship_length || '-'} m</span>
        </div>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Bredde:</span>
          <span style={styles.infoValue}>{ship.ship_width || '-'} m</span>
        </div>
      </div>
      <div style={styles.infoRow}>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>Heading:</span>
          <span style={styles.infoValue}>{ship.true_heading || '-'}</span>
        </div>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>Kurs:</span>
          <span style={styles.infoValue}>{ship.course_over_ground || '-'}</span>
        </div>
        <div style={styles.infoCol3}>
          <span style={styles.infoLabel}>Hastighet:</span>
          <span style={styles.infoValue}>{ship.speed_over_ground || '-'} knop</span>
        </div>
      </div>
      <div style={styles.infoRow}>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Breddegrad (N):</span>
          <span style={styles.infoValue}>{ship.latitude}</span>
        </div>
        <div style={styles.infoCol2}>
          <span style={styles.infoLabel}>Lengdegrad (E):</span>
          <span style={styles.infoValue}>{ship.longitude}</span>
        </div>
      </div>
      <div style={styles.buttonContainer}>
        <button style={styles.actionButton} onClick={() => alert('Foreslå endring')}>
          Foreslå endring
        </button>
        <button style={styles.actionButton} onClick={() => alert('Mer info')}>
          Mer info
        </button>
      </div>
    </div>
  );
};

const MemoizedDetailView = React.memo(DetailView);

const getStyles = (theme) => {
  const isLight = theme === 'light';
  return {
    rootContainer: {
      display: 'flex',
      height: 'calc(100vh - 71px)',
      backgroundColor: isLight ? '#f5f5f5' : '#2e2e2e',
    },
    leftContainer: {
      width: '350px',
      backgroundColor: isLight ? '#ffffff' : '#3a3a3a',
      borderRight: isLight ? '1px solid #ccc' : '1px solid #555',
      padding: '10px',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    title: {
      margin: 0,
      marginBottom: '10px',
      fontSize: '18px',
      color: isLight ? '#000' : '#fff',
    },
    searchInput: {
      width: '96%',
      height: '30px',
      borderRadius: '5px',
      paddingLeft: '10px',
      color: isLight ? '#000' : '#fff',
      backgroundColor: isLight ? '#eee' : '#555',
      border: 'none',
      marginBottom: '10px',
    },
    searchOptionsContainer: {
      marginBottom: '10px',
    },
    searchOptionsToggle: {
      fontSize: '14px',
      color: isLight ? '#007bff' : '#66bfff',
      cursor: 'pointer',
      textDecoration: 'underline',
      marginBottom: '5px',
      fontWeight: 'bold',
      padding: '3px 5px',
      borderRadius: '3px',
    },
    searchOptionsPanel: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginBottom: '10px',
    },
    searchOptionLabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      color: isLight ? '#000' : '#fff',
    },
    selectionButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    selectionButton: {
      flex: 1,
      marginRight: '5px',
      padding: '5px 10px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: isLight ? '#4e73df' : '#555',
      color: '#fff',
      cursor: 'pointer',
    },
    shipList: {
      flex: 1,
      overflowY: 'auto',
      marginTop: '10px',
    },
    shipListItem: {
      marginBottom: '10px',
      padding: '6px 10px',
      borderRadius: '5px',
      backgroundColor: isLight ? '#fafafa' : '#444',
      color: isLight ? '#000' : '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    shipInfoContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    iconsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
    },
    infoButton: {
      width: '24px',
      height: '24px',
      border: 'none',
      backgroundColor: isLight ? '#007bff' : '#007bff',
      color: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
      padding: 0,
    },
    mapButton: {
      width: '24px',
      height: '24px',
      border: 'none',
      backgroundColor: isLight ? '#28a745' : '#28a745',
      color: '#fff',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
      padding: 0,
    },
    mapContainer: {
      flex: 1,
      position: 'relative',
    },
    map: {
      width: '100%',
      height: '100%',
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    backButton: {
      alignSelf: 'flex-start',
      marginBottom: '10px',
      padding: '5px 10px',
      cursor: 'pointer',
      backgroundColor: isLight ? '#ddd' : '#555',
      borderRadius: '5px',
      border: 'none',
      color: isLight ? '#000' : '#fff',
    },
    detailHeader: {
      marginTop: 0,
      marginBottom: '15px',
      fontSize: '18px',
      color: isLight ? '#000' : '#fff',
      borderBottom: `2px solid ${isLight ? '#ccc' : '#555'}`,
      paddingBottom: '5px',
    },
    infoRow: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: '10px',
      borderBottom: `1px solid ${isLight ? '#ccc' : '#555'}`,
      paddingBottom: '10px',
    },
    infoLabel: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: isLight ? '#000' : '#fff',
    },
    infoValue: {
      fontSize: '14px',
      color: isLight ? '#333' : '#ccc',
    },
    infoCol1: {
      flex: '0 0 98%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
    },
    infoCol2: {
      flex: '0 0 48%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
    },
    infoCol3: {
      flex: '0 0 29%',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '10px',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 71px)',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
    },
    actionButton: {
      flex: 1,
      marginRight: '10px',
      padding: '8px 12px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: isLight ? '#4e73df' : '#555',
      color: '#fff',
      cursor: 'pointer',
    },
  };
};

export default MapScreen;
