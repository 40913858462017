import React, { useEffect, useState, useMemo } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Switch from 'react-switch';

const DEFAULT_IMAGE_URL = 'https://pmh-intern.no/default.webp';

const PMHShipsTableScreen = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [pmhShips, setPmhShips] = useState([]);
  const [filteredShips, setFilteredShips] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAllShips, setShowAllShips] = useState(false);
  const [loading, setLoading] = useState(false);

  // Kan være: 'table', 'cards', 'grid'
  const [viewMode, setViewMode] = useState('grid');

  const styles = getStyles(theme);
  const customStyles = getCustomTableStyles(theme);
  const conditionalRowStyles = getConditionalRowStyles(theme);

  // --- Oppdater searchQuery fra URL ved oppstart ---
  useEffect(() => {
    const searchFromUrl = searchParams.get('search');
    if (searchFromUrl) {
      setSearchQuery(searchFromUrl);
    }
  }, [searchParams]);

  // --- Oppdater URL når søk endres ---
  useEffect(() => {
    if (searchQuery) {
      setSearchParams({ search: searchQuery });
    } else {
      setSearchParams({});
    }
  }, [searchQuery, setSearchParams]);

  // --- Kolonner for table mode ---
  const columns = [
    {
      name: 'Navn',
      selector: (row) => row.name ?? '-',
      sortable: true,
      wrap: true,
      cell: (row) => (
        <Link
          to={`/ship/${row.mmsi}`}
          style={{
            color: theme === 'light' ? '#007bff' : '#66baff',
            textDecoration: 'none',
          }}
        >
          {row.name ?? '-'}
        </Link>
      ),
    },
    { name: 'Eier', selector: (row) => row.ship_owner ?? '-', sortable: true, wrap: true },
    { name: 'Verft', selector: (row) => row.yard ?? '-', sortable: true, wrap: true },
    { name: 'Byggenummer', selector: (row) => row.build_number ?? '-', sortable: true, wrap: true },
    { name: 'Design', selector: (row) => row.design ?? '-', sortable: true, wrap: true },
    { name: 'Skrog', selector: (row) => row.hull ?? '-', sortable: true, wrap: true },
    { name: 'Tidligere navn', selector: (row) => row.prev_names ?? '-', sortable: true, wrap: true },
    { name: 'Lengde', selector: (row) => row.ship_length ?? '-', sortable: true, wrap: true },
    { name: 'Bredde', selector: (row) => row.ship_width ?? '-', sortable: true, wrap: true },
    { name: 'MMSI', selector: (row) => row.mmsi ?? '-', sortable: true, wrap: true },
    { name: 'Kjennesignal', selector: (row) => row.call_sign ?? '-', sortable: true, wrap: true },
    { name: 'IMO', selector: (row) => row.imo ?? '-', sortable: true, wrap: true },
  ];

  // --- Hent data fra API ---
  useEffect(() => {
    const fetchShips = async () => {
      setLoading(true);
      try {
        const shipFilter = showAllShips ? 'all_ships' : 'pmh_ships';
        const token = localStorage.getItem('token');
        if (!token) {
          return;
        }
        const response = await fetch(
          `https://pmh-intern.no:8500/api/v1/ship/info?shipFilter=${shipFilter}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.status === 403) {
          console.log('logg ut!');
          window.location.href = '/login';
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data) {
          const shipArray = Object.values(data);
          setPmhShips(shipArray);
          setFilteredShips(shipArray);
        }
      } catch (error) {
        console.error('Feil ved henting av skip:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchShips();
  }, [showAllShips]);

  // --- Søk i data (lokalt) ---
  useEffect(() => {
    const createSearchRegex = (word) => {
      const escapeRegex = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      let regexStr = '';
      for (const char of word) {
        if (char === 'ø' || char === 'o') {
          regexStr += '(?:o|ø|oe)';
        } else if (char === 'å' || char === 'a') {
          regexStr += '(?:a|å|aa)';
        } else if (char === 'æ') {
          regexStr += '(?:æ|a|e|ae)';
        } else {
          regexStr += escapeRegex(char);
        }
      }
      return new RegExp(regexStr, 'i');
    };

    const searchWords = searchQuery.toLowerCase().split(' ').filter(Boolean);
    const filteredData = pmhShips.filter((ship) => {
      const shipString = [
        ship.name,
        ship.call_sign,
        ship.imo,
        ship.ship_owner,
        ship.build_number,
        ship.yard,
        ship.design,
        ship.hull,
        ship.prev_names,
        ship.ship_length,
        ship.ship_width,
        ship.keywords,
      ]
        .join(' ')
        .toLowerCase();
      return searchWords.every((word) => {
        const regex = createSearchRegex(word);
        return regex.test(shipString);
      });
    });
    setFilteredShips(filteredData);
  }, [searchQuery, pmhShips]);

  // Naviger til skip-detaljside (brukes når en rad klikkes i table mode)
  const navigateToShipDetail = (mmsi) => {
    navigate(`/ship/${mmsi}`);
  };

  // --- RENDER-FUNKSJONER FOR ULIKE VISNINGSMODUSER ---

  // 1) Tabellvisning
  const renderTableMode = () => {
    return (
      <div style={styles.tableWrapper}>
        <DataTable
          columns={columns}
          data={filteredShips}
          pagination
          highlightOnHover
          pointerOnHover
          responsive
          striped
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
          onRowClicked={(row) => navigateToShipDetail(row.mmsi)}
        />
      </div>
    );
  };

  // 2) Listevisning
  const renderListMode = () => {
    if (filteredShips.length === 0) {
      return <div style={styles.noShipsFound}>Ingen skip funnet</div>;
    }
    return (
      <div style={styles.shipsList}>
        {filteredShips.map((ship) => {
          const imageUrl = ship.img_url || DEFAULT_IMAGE_URL;
          return (
            <Link
              key={ship.mmsi}
              to={`/ship/${ship.mmsi}`}
              style={{ ...styles.shipListItem, textDecoration: 'none' }}
            >
              <img
                src={imageUrl}
                alt={ship.name || 'Ukjent skip'}
                style={styles.listItemImage}
              />
              <div style={styles.listItemDetails}>
                <h3 style={styles.shipName}>{ship.name || 'Ukjent'}</h3>
                <p style={styles.shipInfo}>Verft: {ship.yard || 'Ukjent'}</p>
                <p style={styles.shipInfo}>Byggenr: {ship.build_number || 'Ukjent'}</p>
                <p style={styles.shipInfo}>Eier: {ship.ship_owner || 'Ukjent'}</p>
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  // 3) Gridvisning
  const renderGridMode = () => {
    if (filteredShips.length === 0) {
      return <div style={styles.noShipsFound}>Ingen skip funnet</div>;
    }
    return (
      <div style={styles.shipsGrid}>
        {filteredShips.map((ship) => {
          const imageUrl = ship.img_url || DEFAULT_IMAGE_URL;
          return (
            <Link
              key={ship.mmsi}
              to={`/ship/${ship.mmsi}`}
              style={{ ...styles.gridItem, textDecoration: 'none' }}
            >
              <img
                src={imageUrl}
                alt={ship.name || 'Ukjent skip'}
                style={styles.gridItemImage}
              />
              <div style={styles.gridItemDetails}>
                <h3 style={styles.gridItemName}>{ship.name || 'Ukjent skip'}</h3>
                <p style={styles.gridItemInfo}>
                  <b>Byggenr:</b> {ship.build_number || 'Ukjent'}
                </p>
                <p style={styles.gridItemInfo}>
                  <b>Verft:</b> {ship.yard || 'Ukjent'}
                </p>
                <p style={styles.gridItemInfo}>
                  <b>Eier:</b> {ship.ship_owner || 'Ukjent'}
                </p>
                <p style={styles.gridItemInfo}>
                  <b>MMSI:</b> {ship.mmsi || 'Ukjent'}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    );
  };

  // Velg hva som skal rendres basert på viewMode:
  const renderContent = () => {
    if (loading) {
      return <div style={styles.loading}>Laster...</div>;
    }
    switch (viewMode) {
      case 'table':
        return renderTableMode();
      case 'cards':
        return renderListMode();
      case 'grid':
        return renderGridMode();
      default:
        return renderListMode();
    }
  };

  return (
    <div style={styles.container}>
      {/* Søk og toggler */}
      <div style={styles.searchContainer}>
        <input
          style={styles.input}
          type="text"
          placeholder="Søk etter skip, verft, byggenummer, mm..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Toggle for “Alle skip” */}
        <div style={styles.toggleWrapper}>
          <span style={styles.toggleLabelText}>
            {showAllShips ? 'Alle skip' : 'Alle skip'}
          </span>
          <Switch
            onChange={(val) => setShowAllShips(val)}
            checked={showAllShips}
            onColor={theme === 'light' ? '#007bff' : '#007bff'}
            offColor={theme === 'light' ? '#bbb' : '#555'}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={18}
            height={22}
            width={40}
          />
        </div>

        {/* Knappegruppe for visningsmodus */}
        <div style={styles.viewModeButtons}>
          <button
            style={{
              ...styles.viewModeButton,
              ...(viewMode === 'table' ? styles.viewModeButtonActive : {}),
            }}
            onClick={() => setViewMode('table')}
          >
            Tabell
          </button>
          <button
            style={{
              ...styles.viewModeButton,
              ...(viewMode === 'cards' ? styles.viewModeButtonActive : {}),
            }}
            onClick={() => setViewMode('cards')}
          >
            Liste
          </button>
          <button
            style={{
              ...styles.viewModeButton,
              ...(viewMode === 'grid' ? styles.viewModeButtonActive : {}),
            }}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
        </div>
      </div>

      {/* Hovedinnhold */}
      {renderContent()}
    </div>
  );
};


// --- STYLING ---

const getStyles = (theme) => {
  const isLight = theme === 'light';
  return {
    container: {
      backgroundColor: isLight ? '#f5f5f5' : '#2e2e2e',
      color: isLight ? '#000000' : '#ffffff',
      height: 'calc(100vh - 111px)',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto',
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
      width: '100%',
      gap: '20px',
      flexWrap: 'wrap',
    },
    input: {
      width: '300px',
      padding: '10px',
      border: isLight ? '1px solid #cccccc' : '1px solid #555555',
      borderRadius: '5px',
      backgroundColor: isLight ? '#ffffff' : '#555555',
      color: isLight ? '#000000' : '#ffffff',
    },
    toggleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    toggleLabelText: {
      fontSize: '14px',
      color: isLight ? '#000000' : '#ffffff',
    },
    viewModeButtons: {
      display: 'flex',
      gap: '10px',
    },
    viewModeButton: {
      border: 'none',
      outline: 'none',
      borderRadius: '5px',
      padding: '8px 12px',
      backgroundColor: isLight ? '#eee' : '#555',
      color: isLight ? '#000' : '#fff',
      cursor: 'pointer',
      fontSize: '14px',
      transition: 'background-color 0.2s',
    },
    viewModeButtonActive: {
      backgroundColor: isLight ? '#007bff' : '#007bff',
      color: '#fff',
    },
    loading: {
      textAlign: 'center',
      fontSize: '18px',
    },
    tableWrapper: {
      width: '98%',
      margin: '0 auto',
      overflowX: 'auto',
      borderRadius: '5px',
      padding: '10px',
    },
    // Listevisning (cards)
    shipsList: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '800px',
      gap: '10px',
    },
    shipListItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: isLight ? '#fafafa' : '#444444',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      position: 'relative',
    },
    listItemImage: {
      width: '80px',
      height: '80px',
      objectFit: 'cover',
      borderRadius: '5px',
      marginRight: '15px',
    },
    listItemDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3px',
    },
    shipName: {
      margin: 0,
      fontWeight: 'bold',
      color: isLight ? '#000000' : '#ffffff',
      fontSize: '16px',
    },
    shipInfo: {
      fontSize: '13px',
      color: isLight ? '#666666' : '#aaaaaa',
      margin: 0,
    },
    noShipsFound: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '18px',
    },
    // Gridvisning
    shipsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '20px',
      width: '100%',
      maxWidth: '1200px',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: isLight ? '#ffffff' : '#444444',
      border: isLight ? '1px solid #ccc' : '1px solid #555',
      borderRadius: '5px',
      cursor: 'pointer',
      overflow: 'hidden',
      transition: 'background-color 0.2s',
    },
    gridItemImage: {
      width: '100%',
      height: '180px',
      objectFit: 'cover',
    },
    gridItemDetails: {
      padding: '15px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    gridItemName: {
      margin: 0,
      fontSize: '16px',
      fontWeight: 'bold',
      color: isLight ? '#000000' : '#ffffff',
      marginBottom: '6px',
    },
    gridItemInfo: {
      margin: 0,
      fontSize: '13px',
      color: isLight ? '#666666' : '#aaaaaa',
      lineHeight: '1.4em',
    },
  };
};

const getCustomTableStyles = (theme) => {
  const isLight = theme === 'light';
  return {
    table: {
      style: {
        width: '100%',
        tableLayout: 'auto',
        backgroundColor: isLight ? '#ffffff' : '#444444',
      },
    },
    header: {
      style: {
        backgroundColor: isLight ? '#f0f0f0' : '#333333',
        color: isLight ? '#000000' : '#ffffff',
      },
    },
    headRow: {
      style: {
        backgroundColor: isLight ? '#e0e0e0' : '#3a3a3a',
        borderBottom: isLight ? '1px solid #999' : '1px solid #555',
      },
    },
    headCells: {
      style: {
        color: isLight ? '#000000' : '#ffffff',
        fontWeight: 'bold',
        fontSize: '15px',
      },
    },
    rows: {
      style: {
        fontSize: '14px',
        borderBottom: isLight ? '1px solid #ccc' : '1px solid #555',
        backgroundColor: isLight ? '#ffffff' : '#2e2e2e',
        color: isLight ? '#000000' : '#ffffff',
      },
      stripedStyle: {
        backgroundColor: isLight ? '#fafafa' : '#2a2a2a',
        color: isLight ? '#000000' : '#ffffff',
      },
      highlightOnHoverStyle: {
        backgroundColor: isLight ? '#ececec' : '#3a3a3a',
        color: isLight ? '#000000' : '#ffffff',
        outline: 'none',
      },
    },
    pagination: {
      style: {
        backgroundColor: isLight ? '#ffffff' : '#2e2e2e',
        color: isLight ? '#000000' : '#ffffff',
      },
      pageButtonsStyle: {
        fill: isLight ? '#000000' : '#ffffff',
        color: isLight ? '#000000' : '#ffffff',
        backgroundColor: 'transparent',
      },
    },
  };
};

const getConditionalRowStyles = (theme) => {
  const isLight = theme === 'light';
  return [
    {
      when: (row, index) => index % 2 === 1,
      style: {
        backgroundColor: isLight ? '#f9f9f9' : '#383838',
      },
    },
  ];
};

export default PMHShipsTableScreen;
