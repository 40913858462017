import React, { useState } from 'react';

const LoadCalculator = ({ styles }) => {
  const [load4T, setLoad4T] = useState('');
  const [load10T, setLoad10T] = useState('');

  // Konverter input til tall. Bruk 0 hvis input ikke er gyldig.
  const numLoad4T = parseFloat(load4T) || 0;
  const numLoad10T = parseFloat(load10T) || 0;

  // Kalkuler forskjellen og faktoren
  const difference = numLoad10T - numLoad4T;
  const factor = difference / 6;

  // Kalkuler outputverdiene
  const load20T = numLoad10T + factor * 10;
  const load30T = numLoad10T + factor * 20;
  const load40T = numLoad10T + factor * 30;
  const load50T = numLoad10T + factor * 40;
  const load60T = numLoad10T + factor * 50;

  return (
    <div style={styles?.contentContainer || { padding: '20px' }}>
      <h2>Lastverdier</h2>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Load&nbsp; &nbsp;4T:
          <input
            type="number"
            value={load4T}
            onChange={(e) => setLoad4T(e.target.value)}
            style={styles?.input || { marginLeft: '10px' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <label>
          Load 10T:
          <input
            type="number"
            value={load10T}
            onChange={(e) => setLoad10T(e.target.value)}
            style={styles?.input || { marginLeft: '10px' }}
          />
        </label>
      </div>
      <div>
        <h3>Utregninger:</h3>
        <p>Load 20T: {load20T}</p>
        <p>Load 30T: {load30T}</p>
        <p>Load 40T: {load40T}</p>
        <p>Load 50T: {load50T}</p>
        <p>Load 60T: {load60T}</p>
      </div>
    </div>
  );
};

export default LoadCalculator;
