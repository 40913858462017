// ServiceScreen.jsx
import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Icon } from '@iconify/react';
import Tool4 from './tool/Tool4';
import Tool2 from './tool/changeLog';
import ShipEditScreen from './tool/editShip';
import { useNavigate, useLocation } from 'react-router-dom'; // Importer hookene

// Legg til 'route'-egenskap for hvert verktøy
const menuItems = {
  tool_4: { name: 'Serverstatus', icon: 'mdi:lan', route: '/tools/server-status' },
  tool_1: { name: 'SDIR Fetch Log', icon: 'mdi:chip', route: '/tools/sdir-fetch-log' },
  tool_2: { name: 'SDIR Change Log', icon: 'mdi:clipboard-text', route: '/tools/sdir-change-log' },
  tool_3: { name: 'PMH-Ship Name Changes', icon: 'mdi:account-group', route: '/tools/pmh-ship-name-changes' },
  tool_5: { name: 'Rediger skip', icon: 'mdi:wrench', route: '/tools/edit-ship' },
};


const ServiceScreen = () => {
  const { theme } = useTheme();
  const [selectedMenu, setSelectedMenu] = useState('tool_4'); // Default: Tool 1
  const [users, setUsers] = useState([]);
  const navigate = useNavigate(); // Hook for navigasjon
  const location = useLocation(); // Hook for å hente nåværende URL
  const styles = getStyles(theme);

  // Eksempel: Hent brukere (brukes evt. på en egen side)
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch('https://pmh-intern.no:8502/api/users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Feil ved henting av brukere');
        }

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  // Synkroniser valgt meny med URL-en ved sideinnlasting/URL-endring
  useEffect(() => {
    const currentRoute = location.pathname;
    const matchingKey = Object.keys(menuItems).find(
      key => menuItems[key].route === currentRoute
    );
    if (matchingKey) {
      setSelectedMenu(matchingKey);
    }
  }, [location.pathname]);

  // Handler for menyvalg
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    // Oppdater URL basert på valgt verktøy
    navigate(menuItems[menu].route);
  };

  // Render innhold basert på valgt meny
  const renderContent = () => {
    switch (selectedMenu) {
      case 'tool_1':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 1</h2>
            <p>Her kan du bruke verktøy 1.</p>
          </div>
        );
      case 'tool_2':
        return <Tool2 styles={styles} />;
      case 'tool_3':
        return (
          <div style={styles.contentContainer}>
            <h2>Verktøy 3</h2>
            <p>Her kan du bruke verktøy 3.</p>
          </div>
        );
      case 'tool_4':
        return <Tool4 styles={styles} />;
      case 'tool_5':
        return <ShipEditScreen styles={styles} />;
      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      {/* Sidebar */}
      <div style={styles.sidebar}>
        <h3 style={styles.sidebarHeader}>Meny</h3>
        {Object.entries(menuItems).map(([key, { name, icon }]) => (
          <div
            key={key}
            style={{
              ...styles.sidebarItem,
              backgroundColor:
                selectedMenu === key ? (theme === 'light' ? '#e0e0e0' : '#444444') : 'transparent',
            }}
            onClick={() => handleMenuClick(key)}
          >
            <Icon icon={icon} style={styles.menuIcon} />
            {name}
          </div>
        ))}
      </div>

      {/* Hovedinnhold for verktøy – denne containeren er scrollbar */}
      <div className="yard-scrollbar" style={styles.mainContent}>
        {renderContent()}
      </div>

      {/* Global CSS for scrollbars */}
      <style jsx>{`
        .yard-scrollbar {
          scrollbar-width: thin;
          scrollbar-color: ${theme === 'light' ? '#aaa #f0f0f0' : '#666 #2e2e2e'};
        }
        .yard-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .yard-scrollbar::-webkit-scrollbar-track {
          background: ${theme === 'light' ? '#f0f0f0' : '#2e2e2e'};
          border-radius: 10px;
        }
        .yard-scrollbar::-webkit-scrollbar-thumb {
          background-color: ${theme === 'light' ? '#ccc' : '#666'};
          border-radius: 10px;
          border: 2px solid transparent;
        }
        .yard-scrollbar::-webkit-scrollbar-thumb:hover {
          background-color: ${theme === 'light' ? '#999' : '#444'};
        }
      `}</style>
    </div>
  );
};

// Funksjon for å hente CSS-stiler basert på tema
const getStyles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 71px)',
    backgroundColor: theme === 'light' ? '#ffffff' : '#1e1e1e',
    overflow: 'hidden', // Hovedsiden skal ikke scrolles
  },
  sidebar: {
    width: '250px',
    backgroundColor: theme === 'light' ? '#f4f4f4' : '#333333',
    padding: '15px',
    borderRight: `1px solid ${theme === 'light' ? '#dddddd' : '#444444'}`,
  },
  sidebarHeader: {
    color: theme === 'light' ? '#000000' : '#ffffff',
    marginBottom: '15px',
  },
  sidebarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    color: theme === 'light' ? '#000000' : '#ffffff',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
  },
  menuIcon: {
    marginRight: '10px',
  },
  mainContent: {
    flex: 1,
    padding: '20px',
    backgroundColor: theme === 'light' ? '#ffffff' : '#2e2e2e',
    color: theme === 'light' ? '#000000' : '#ffffff',
    overflowY: 'auto', // Her scrolles innholdet om nødvendig
  },
  contentContainer: {
    backgroundColor: theme === 'light' ? '#ffffff' : '#2c2c2c',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: theme === 'light'
      ? '0px 2px 10px rgba(0,0,0,0.1)'
      : '0px 2px 10px rgba(0,0,0,0.5)',
  },
  searchInput: {
    width: '100%',
    padding: '12px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    backgroundColor: theme === 'light' ? '#fff' : '#3c3c3c',
    color: theme === 'light' ? '#000' : '#fff',
  },
  tableContainer: {
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    padding: '12px',
    backgroundColor: theme === 'light' ? '#f0f0f0' : '#444',
    color: theme === 'light' ? '#000' : '#fff',
    textAlign: 'left',
    borderBottom: '2px solid #ddd',
    cursor: 'pointer',
  },
  td: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    color: theme === 'light' ? '#000' : '#fff',
  },
  trEven: {
    backgroundColor: theme === 'light' ? '#fafafa' : '#3c3c3c',
    transition: 'background-color 0.3s ease',
  },
  trOdd: {
    backgroundColor: theme === 'light' ? '#ffffff' : '#333333',
    transition: 'background-color 0.3s ease',
  },
});

export default ServiceScreen;
