import React, { useEffect, useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Icon } from '@iconify/react';

const HomeScreen = () => {
  const { theme } = useTheme();
  const styles = getStyles(theme);
  const [shipNameChanges, setShipNameChanges] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Antall som vises
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShipNameChanges = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('Du er ikke logget inn.');
        return;
      }

      try {
        const response = await fetch('https://pmh-intern.no:8503/api/pmh-ship-name-changes', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response.status)

        if(response.status === 403) {
          console.log("logg ut!")
          window.location.href = "/login";
        }

        if (!response.ok) {
          throw new Error(`Feil under lasting av data: ${response.statusText}`);
        }

        const data = await response.json();

        // Filtrer kun godkjente endringer og sorter etter tid (nyeste først)
        const approvedChanges = data
          .filter((ship) => ship.status === 'approved')
          .sort((a, b) => new Date(b.time) - new Date(a.time));

        setShipNameChanges(approvedChanges);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchShipNameChanges();
  }, []);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  return (
    <div style={styles.screenContainer}>
      <header style={styles.header}>
        <h1 style={styles.title}>Velkommen til PMHs internside</h1>
      </header>
      <main style={styles.mainContent}>
        <section style={styles.quickLinks}>
          <h2 style={styles.sectionTitle}>Hurtiglenker</h2>
          <div style={styles.linksContainer}>
            <button style={styles.linkButton}>Kundeoversikt</button>
            <button style={styles.linkButton}>Interne Dokumenter</button>
            <button style={styles.linkButton}>Ansattportal</button>
          </div>
        </section>
        <section style={styles.shipNameChanges}>
          <h2 style={styles.sectionTitle}>Nylige navneendringer</h2>
          {error ? (
            <p style={styles.errorText}>{error}</p>
          ) : (
            <>
              <div style={{ ...styles.scrollableList, ...styles['::-webkit-scrollbar'], ...styles['::-webkit-scrollbar-thumb'], ...styles['::-webkit-scrollbar-track'] }}>
                <ul style={styles.shipList}>
                  {shipNameChanges.slice(0, visibleCount).map((ship) => (
                    <li key={ship.id} style={styles.shipListItem}>
                      <strong>{ship.old_name} </strong>
                      <Icon icon="majesticons:arrow-right" width="14" height="14" />
                      <strong> {ship.new_name}</strong>
                      <div style={styles.shipDetails}>
                        <span>MMSI: {ship.mmsi}</span>
                        <span>{ship.time}</span>
                      </div>
                    </li>
                  ))}
                </ul>
                {visibleCount < shipNameChanges.length && (
                  <button style={styles.loadMoreButton} onClick={handleLoadMore}>
                    Vis flere
                  </button>
                )}
              </div>

            </>
          )}
        </section>
      </main>
    </div>
  );
};

const getStyles = (theme) => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    height: 'calc(100vh - 111px)',
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#333',
    color: theme === 'light' ? '#000' : '#fff',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  mainContent: {
    width: '100%',
    maxWidth: '800px',
  },
  quickLinks: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  linksContainer: {
    display: 'flex',
    gap: '10px',
  },
  linkButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: theme === 'light' ? '#007bff' : '#555',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  shipNameChanges: {
    marginBottom: '20px',
  },
  scrollableList: {
    maxHeight: '300px', // Sett høyden på containeren
    overflowY: 'auto',
    border: `1px solid ${theme === 'light' ? '#ddd' : '#444'}`,
    borderRadius: '10px',
    padding: '10px',
    scrollbarWidth: 'thin', // For Firefox
    scrollbarColor: `${theme === 'light' ? '#007bff #f5f5f5' : '#555 #333'}`, // For Firefox
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme === 'light' ? '#007bff' : '#555',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#333',
    borderRadius: '10px',
  },
  shipList: {
    listStyleType: 'none',
    padding: '0',
    margin: '0',
  },
  shipListItem: {
    backgroundColor: theme === 'light' ? '#fff' : '#2a2a2a',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: theme === 'light' ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 4px 8px rgba(0, 0, 0, 0.3)',
    marginBottom: '10px',
  },
  shipDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: theme === 'light' ? '#666' : '#bbb',
  },
  loadMoreButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: theme === 'light' ? '#007bff' : '#555',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '15px',
    transition: 'background-color 0.3s',
  },
  errorText: {
    color: 'red',
    fontSize: '16px',
  },
});

export default HomeScreen;
